import { TextAndLink } from 'components'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent } from 'models'
import { ML_HELP_LINK } from 'utilities'

interface Props {
  activeStep: number
  isSuccessPage: boolean
  title: string
  isOnSubStep?: boolean
  analyticsEventName?: string
}

export function LoginMfaHeader({
  activeStep,
  isSuccessPage,
  title,
  isOnSubStep = false,
  analyticsEventName = '',
}: Props) {
  const { isDesktop } = useBreakpoint()

  return (
    <div
      className={`mb-7 grid w-full grid-cols-[auto-cols-max] content-end px-6 pt-7 text-right lg:py-7 lg:px-0 ${
        isSuccessPage ? 'hidden' : ''
      }`}
    >
      {/* <Back
        className="justify-self-start"
        analyticsEventName={`${analyticsEventName}: ${AnalyticsEvent.Back}`}
      /> */}
      {!isDesktop || isSuccessPage ? (
        <div className="justify-self-start pl-5 text-m-h2">{title}</div>
      ) : (
        <TextAndLink
          text={`Having trouble?${' '}`}
          external
          link={ML_HELP_LINK}
          className="justify-self-end"
          linkText="Get help"
          analyticsEventName={`${analyticsEventName}: ${AnalyticsEvent.Help}`}
        />
      )}
    </div>
  )
}
