import { AuthTransaction, OktaAuth } from '@okta/okta-auth-js'
import {
  forgotPassword,
  resendCode,
} from 'components/mfa/forgot-password/auth-functions'
import { OPEN_ID_CONFIG } from 'config/openid.config'
import { useAnalytics, useAuth, useLocalStorage } from 'hooks'
import {
  AnalyticsEvent,
  AnalyticsPage,
  ChangePasswordConfig,
  MfaStep,
  getForgotPasswordPageName,
  OtpFactorType,
} from 'models'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AUTHORIZE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  isSetPasswordFlow,
  LOGIN_USERNAME_KEY,
  notifyBugsnag,
  SET_PASSWORD_ROUTE,
} from 'utilities'

export const ChangePasswordContext = createContext<ChangePasswordConfig>(
  getInitialValue(false)
)

ChangePasswordContext.displayName = 'ChangePassword'

export const useChangePasswordContext = () => useContext(ChangePasswordContext)

interface Props {
  children: React.ReactNode
}

export const ChangePasswordContextProvider = ({ children }: Props) => {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const { trackClickEvent } = useAnalytics()
  const isSetPassword = isSetPasswordFlow()
  const initial = getInitialValue(isSetPassword)
  const [activeStep, setActiveStep] = useState<number>(initial.activeStep)
  const [steps, setSteps] = useState<MfaStep[]>(initial.steps)
  const [title, setTitle] = useState<string>(initial.title)
  const [subtitle, setSubtitle] = useState<string>(initial.subtitle)
  const [mobileTitle, setMobileTitle] = useState<string>(initial.mobileTitle)
  const [mobileSubtitle, setMobileSubtitle] = useState<string>(
    initial.mobileSubtitle
  )
  const [isOnSubStep, setIsOnSubStep] = useState<boolean>(false)

  const [authError, setAuthError] = useState<any>()
  const [authTransaction, setAuthTransaction] = useState<AuthTransaction>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [username] = useLocalStorage<string>(LOGIN_USERNAME_KEY, '')
  const [factorType, setFactorType] = useState<OtpFactorType>('')
  const [verifyCode, setVerifyCode] = useState<string>('')

  const { pathname } = useLocation()

  /**
   * Reset the user's active step and isOnSubstep values
   * on a browser refresh based on the pathname
   */
  useEffect(() => {
    const step = +pathname.split('/').splice(-1).toString()
    setIsOnSubStep(step === 2)
    handleInvalidState(step)
  }, [authTransaction, pathname])

  /**
   * Reset state if the user is ever in an invalid state
   * which could include:
   *    - user refreshed within the forgot password and wiped
   *      out the authTransaction state
   *    - user clicked browser back after successful pw reset
   *    - is on the verification code entry step without `otpResend`
   *      or `verify` functions available on the auth transaction
   *    - is on the create new password step without `resetPassword`
   *      function available on the auth transaction
   */
  const handleInvalidState = (step: number) => {
    let shouldResetState = false

    if (step < 2 && authTransaction?.status === 'SUCCESS') {
      shouldResetState = true
    } else if (
      step === 2 &&
      (!authTransaction?.resend || !authTransaction.verify)
    ) {
      shouldResetState = true
    } else if (step === 3 && !authTransaction?.resetPassword) {
      shouldResetState = true
    } else if (step === 4 && !authTransaction) {
      shouldResetState = true
    }

    if (shouldResetState) goBack(step)
  }

  /**
   * Set the name of the Forgot Password current page
   * based on context metadata for use with analytics logging
   * since the route doesn't change within the forgot password
   * flow
   */
  const analyticsPageName = useMemo(
    () => getForgotPasswordPageName(activeStep, isOnSubStep, factorType),
    [activeStep, isOnSubStep, factorType]
  )

  /**
   * Do not prepend forgot password event names with `${AnalyticsPage.ResetPassword}: `
   */
  const analyticsEventName = useMemo(
    () => analyticsPageName.replace(`${AnalyticsPage.ResetPassword}: `, ''),
    [analyticsPageName]
  )

  /**
   * Set the new active step index based on the incoming value
   * as well as updates meta state
   * @param stepIndex
   */
  const updateActiveStep = (stepIndex: number) => {
    const newStep = steps[`${stepIndex}`]
    setSteps(getUpdatedSteps(stepIndex))
    setActiveStep(stepIndex)
    setTitle(newStep.title)
    setSubtitle(newStep.subtitle)
    setMobileTitle(newStep.mobileTitle)
    setMobileSubtitle(newStep.mobileSubtitle)
  }

  /**
   * Update the value that determines whether to display a sub step or not
   *
   * @param value the boolean value to update the state with
   */
  const updateIsOnSubStep = (value: boolean) => {
    setIsOnSubStep(value)
    if (value) {
      setTitle(steps[`${activeStep}`].subStep?.title ?? '')
      setSubtitle(steps[`${activeStep}`].subStep?.subtitle ?? '')
      setMobileTitle(steps[`${activeStep}`].subStep?.mobileTitle ?? '')
      setMobileSubtitle(steps[`${activeStep}`].subStep?.mobileSubtitle ?? '')
    } else {
      setTitle(steps[`${activeStep}`].title)
      setSubtitle(steps[`${activeStep}`].subtitle)
      setMobileTitle(steps[`${activeStep}`].mobileTitle ?? '')
      setMobileSubtitle(steps[`${activeStep}`].mobileSubtitle ?? '')
    }
  }

  /**
   * Update the steps array to set the previous step as completed
   *    and the current step as not completed
   *
   * Do not attempt to set a value for a step at a negative index as
   *    that will throw an error
   *
   * If the activeIndex is 0, set the steps as the initial values which
   *    effectively sets all steps to completed=false
   */
  function getUpdatedSteps(activeIndex: number): MfaStep[] {
    if (activeIndex > 0) steps[`${activeIndex - 1}`].isCompleted = true
    else {
      steps.map((step) => (step.isCompleted = false))
    }
    steps[`${activeIndex}`].isCompleted = false
    return [...steps]
  }

  /**
   * Manage state when user clicks the in-app back button
   *
   * Reset any errors and the auth transaction
   * Navigate the user back to the first step
   * If already on the first step, take the user back to the
   *    password page
   */
  function goBack(step: number = activeStep): void {
    if (activeStep > 0 && step > 0) {
      setAuthError(undefined)
      setAuthTransaction(undefined)
      setIsSubmitting(false)
      updateIsOnSubStep(false)
      updateActiveStep(0)
      navigate(
        `${isSetPassword ? SET_PASSWORD_ROUTE : FORGOT_PASSWORD_ROUTE}/1`
      )
    } else {
      navigate(AUTHORIZE_ROUTE)
    }
  }

  /**
   * Call the forgotPassword function for the given factor type to trigger
   * an action that will either text or call the user to provide a
   * verification code
   *
   * @param event submit event
   * @param factor either 'sms' or 'call'
   */
  async function forgotPasswordWithFactor(event: any, factor: OtpFactorType) {
    event.preventDefault()
    setFactorType(factor)
    try {
      const transaction = await forgotPassword(auth, username, factor)
      setAuthTransaction(transaction)
    } catch (error) {
      notifyBugsnag({ error: error as Error, name: 'Auth-ForgotPassword' })
      setAuthError(error)
    }
  }

  const onResendCode = async (event: any) => {
    event.preventDefault()
    setAuthError(undefined)
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.ResendCode}`,
    })
    await resendCode(authTransaction)
  }

  const onCall = async (event: any) => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.CallInstead}`,
    })
    await forgotPasswordWithFactor(event, 'call')
  }

  const onText = async (event: any) => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.TextInstead}`,
    })
    await forgotPasswordWithFactor(event, 'sms')
  }

  return (
    <ChangePasswordContext.Provider
      value={{
        auth,
        activeStep,
        updateActiveStep,
        isOnSubStep,
        updateIsOnSubStep,
        steps,
        subtitle,
        title,
        mobileSubtitle,
        mobileTitle,
        goBack,
        authError,
        setAuthError,
        authTransaction,
        setAuthTransaction,
        isSubmitting,
        setIsSubmitting,
        username,
        isSetPassword,
        onResendCode,
        onCall,
        onText,
        factorType,
        setFactorType,
        verifyCode,
        setVerifyCode,
        analyticsPageName,
        analyticsEventName,
      }}
    >
      {children}
    </ChangePasswordContext.Provider>
  )
}

function getInitialValue(isSetPasswordFlow: boolean): ChangePasswordConfig {
  return {
    goBack: () => null,
    onCall: async () => await Promise.resolve(null),
    onResendCode: async () => await Promise.resolve(null),
    onText: async () => await Promise.resolve(null),
    setAuthError: () => null,
    setAuthTransaction: () => null,
    setFactorType: () => null,
    setIsSubmitting: () => null,
    setVerifyCode: () => null,
    updateActiveStep: () => null,
    updateIsOnSubStep: () => null,
    analyticsEventName: '',
    analyticsPageName: AnalyticsPage.ResetPassword,
    auth: new OktaAuth(OPEN_ID_CONFIG.oidc),
    activeStep: 0,
    mobileSubtitle: '',
    mobileTitle: 'Choose method of verification',
    subtitle:
      "We can either call or text the phone number associated with your account to provide your verification code. Select your preference and we'll call or text you right away.",
    title: isSetPasswordFlow
      ? 'Before creating your password, we need to make sure it’s you.'
      : 'Before updating your password, we need to make sure it’s you.',
    username: '',
    isSetPassword: false,
    verifyCode: '',
    factorType: 'sms',
    steps: [
      {
        id: 0,
        isCompleted: false,
        name: 'Verification code',
        mobileSubtitle: '',
        mobileTitle: 'Choose method of verification',
        subtitle:
          "We can either call or text the phone number associated with your account to provide your verification code. Select your preference and we'll call or text you right away.",
        title: 'First, we need to verify your identity.',
        hasSubStep: true,
        subStep: {
          name: 'Verification code Sub Step',
          subtitle: 'Please enter the verification code we',
          title: 'Almost there!',
          mobileTitle: 'We’ve sent you a verification code.',
          mobileSubtitle: '',
        },
      },
      {
        id: 1,
        isCompleted: false,
        name: isSetPasswordFlow ? 'Create a password' : 'Create new password',
        subtitle: isSetPasswordFlow
          ? ''
          : 'No recycled passwords here! Your new password must be unique.',
        title: isSetPasswordFlow
          ? 'Create a password'
          : 'Create your new password',
        mobileTitle: isSetPasswordFlow
          ? 'Create a password'
          : 'Create your new password',
        mobileSubtitle: isSetPasswordFlow
          ? ''
          : 'No recycled passwords here! Your new password must be unique.',
      },
      {
        id: 2,
        isCompleted: false,
        name: 'Log in',
        subtitle:
          "You've updated your password successfully. Now you can use your new password to log in.",
        title: 'Success!',
        mobileSubtitle:
          "You've updated your password successfully. Now you can use your new password to log in.",
        mobileTitle: 'Success!',
      },
    ],
  }
}
