import { AnalyticsEvent } from 'models'
import {
  ML_CONTACT_US_LINK,
  ML_LEGAL_LINK,
  ML_PRIVACY_NOTICE_LINK,
} from 'utilities'
import { ExternalLink } from './ExternalLink'

interface Props {
  analyticsPage: string
}

export function LegalDisclaimer({ analyticsPage }: Props) {
  return (
    <div className="mt-2 md:mt-8 lg:mt-8">
      <p className="paragraph-3 text-ink-light">
        By proceeding, you’re agreeing to Mission Lane’s{' '}
        <ExternalLink
          to={ML_LEGAL_LINK}
          className="link"
          newTab={true}
          analyticsEventName={`${analyticsPage}: ${AnalyticsEvent.ConditionsOfUse}`}
        >
          Conditions of Use
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink
          to={ML_PRIVACY_NOTICE_LINK}
          className="link"
          newTab={true}
          analyticsEventName={`${analyticsPage}: ${AnalyticsEvent.PrivacyPolicy}`}
        >
          Privacy Policy
        </ExternalLink>
        {'. '}
        You can{' '}
        <ExternalLink
          to={ML_CONTACT_US_LINK}
          className="link"
          newTab={true}
          analyticsEventName={`${analyticsPage}: ${AnalyticsEvent.ContactUs}`}
        >
          contact us
        </ExternalLink>{' '}
        with questions anytime.
      </p>
    </div>
  )
}
