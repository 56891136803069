import { PrimaryActionButton } from 'components'

interface SubmitActionButtonsType {
  onCancel: () => void
  disableSubmit?: boolean
  submitButtonText?: string
  className?: string
}

export function SubmitActionButtons({
  disableSubmit,
  submitButtonText,
  className = '',
}: SubmitActionButtonsType) {
  return (
    <PrimaryActionButton
      defaultText={submitButtonText ?? 'Proceed'}
      type="submit"
      actingText="Proceeding..."
      isSuccess={false}
      successText="Success!"
      isActing={false}
      disabled={disableSubmit}
      className={`border-blue-600 ${className}`}
    />
  )
}
