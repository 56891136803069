interface Props {
  isActive?: boolean
}

export function OneIcon({ isActive }: Props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="13"
        r="11.5"
        className={isActive ? 'stroke-blue stroke-[2px]' : 'stroke-grey'}
      />
      <path
        d="M11.872 16.5H13.528V7.8H12.268C11.848 8.748 11.26 8.832 10 8.868V9.996H11.872V16.5Z"
        className={isActive ? 'fill-blue' : 'fill-grey'}
      />
    </svg>
  )
}
