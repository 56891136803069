import { Line } from './Line'

interface Props {
  text?: string
  className?: string
}

export function Divider({ text = '', className = '' }: Props) {
  return (
    <div
      className={`grid grid-flow-col grid-cols-[1fr_min-content_1fr] pt-even-larger pb-extra-large text-grey-light ${className}`}
    >
      <Line />
      <span className="mx-extra-large">{text}</span>
      <Line />
    </div>
  )
}
