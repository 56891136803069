import { AuthTransaction, OktaAuth } from '@okta/okta-auth-js'

/** Initiate forgot password flow for a username */
export const forgotPassword = async (
  auth: OktaAuth,
  username: string,
  factorType: string
): Promise<AuthTransaction> => {
  return await auth.forgotPassword({
    username,
    factorType,
  })
}

/** Verify the auth code */
export const verifyAuthCode = async (
  authTransaction: AuthTransaction | undefined,
  passCode: string
): Promise<AuthTransaction> => {
  if (!authTransaction?.verify) {
    throw new Error('otpVerify transaction is undefined')
  }
  return await authTransaction.verify({
    passCode,
  })
}

/** Resend the security code */
export const resendCode = async (
  authTransaction: AuthTransaction | undefined
): Promise<AuthTransaction> => {
  if (!authTransaction?.resend) {
    throw new Error('otpResend resend function is undefined')
  }
  const factorType = authTransaction.factor?.factorType
  return await authTransaction.resend(factorType)
}

/**
 * Make call to reset password using the user's client-side
 * validated new password
 */
export const resetPassword = async (
  authTransaction: AuthTransaction | undefined,
  newPassword: string
) => {
  if (!authTransaction?.resetPassword) {
    throw new Error('resetPassword function is undefined')
  }
  return await authTransaction.resetPassword({ newPassword })
}
