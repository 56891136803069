import ErrorPanel from '../../shared/Form/ErrorPanel'

interface AuthErrorProps {
  errors: string[] | JSX.Element | string
  className?: string
}

export function AuthError({ className = '', errors }: AuthErrorProps) {
  return <ErrorPanel className={`${className} error`} errors={errors} />
}

export function DefaultAuthError({ className }: { className?: string }) {
  return (
    <AuthError
      errors={"We don't recognize that email or password. Please try again."}
      className={className}
    />
  )
}
