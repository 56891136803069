import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ITmxService, IRiskAuthService, OtpFactor, OtpResponse } from 'services'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth, useRouterQuery } from 'hooks'
import { useQueryParamContext } from 'contexts'
import { ERROR_ROUTE, LOGIN_MFA_ROUTE, LOGOUT_ROUTE } from 'utilities'
import { IRiskAuthContext } from './types'

export const RiskAuthContext = createContext<IRiskAuthContext>({
  isAuthenticated: false,
  challengeSession: async () =>
    await Promise.resolve({ success: false, error: 'Something went wrong' }),
  verifySession: async () =>
    await Promise.resolve({ success: false, error: 'Something went wrong' }),
  otpResend: async () =>
    await Promise.resolve({ success: false, error: 'Something went wrong' }),
  getLocalStorageData: () => ({}),
  factors: [],
})

export const useRiskAuthContext = () => useContext(RiskAuthContext)

export const RiskAuthProvider: React.FC<{
  riskAuth: IRiskAuthService
  tmxService: ITmxService
  children: React.ReactNode
}> = ({ riskAuth, tmxService, children }) => {
  const { authState } = useAuth()
  const navigate = useNavigate()
  const [factors, setFactors] = useState<OtpFactor[]>([])
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [selectedFactor, setSelectedFactor] = useState<OtpFactor>()
  const { enableRiskAuthService } = useFlags()
  const originalUri = useRef('/')
  const redirect = useRouterQuery('redirect')
  const loginIntention = useRouterQuery('loginIntention')
  const qp = useQueryParamContext()
  const { pathname } = useLocation()

  // HOOKS

  useEffect(() => {
    loginIntention && localStorage.setItem('loginIntention', loginIntention)
    redirect && localStorage.setItem('redirect', redirect)
  }, [loginIntention, redirect])

  // Trigger code flow after okta is authenticated
  useEffect(() => {
    if (
      authState?.isAuthenticated &&
      typeof enableRiskAuthService === 'boolean'
    ) {
      const fetchData = async () => {
        await handleOktaAuthenticated()
      }
      void fetchData()
    }
  }, [authState?.isAuthenticated])

  // END HOOKS

  // CONSTANTS

  const threatMetrixSessionId = useMemo(() => {
    if (enableRiskAuthService) {
      return tmxService.profileUser()
    }
    return null
  }, [enableRiskAuthService])

  // END CONSTANTS

  // METHODS

  const getLocalStorageData = () => {
    const localStorageLoginIntention = localStorage.getItem('loginIntention')
    const localStorageRedirectUrl = localStorage.getItem('redirect')
    return {
      localStorageLoginIntention,
      localStorageRedirectUrl,
    }
  }

  // After Okta authentication is complete
  const handleOktaAuthenticated = async () => {
    try {
      const { localStorageLoginIntention } = getLocalStorageData()
      if (enableRiskAuthService) {
        const riskAuthLoginIntention = localStorageLoginIntention ?? 'LOGIN'
        const response = await riskAuth.evaluateSession(
          threatMetrixSessionId,
          riskAuthLoginIntention,
          authState?.idToken?.idToken
        )

        if (
          response.multifactorAuthenticationState === 'MFA_NOT_REQUIRED' ||
          response.multifactorAuthenticationState === 'MFA_COMPLETED'
        ) {
          return handleRiskAuthComplete()
        }
        if (response.multifactorAuthenticationState === 'MFA_REQUIRED') {
          const responseFactors = response.factors as OtpFactor[]
          setFactors(responseFactors)
          navigate(LOGIN_MFA_ROUTE)
        }
        // TODO: Investigate if we need to handle MFA_IN_PROGRESS
        // Handle this scenario
      } else {
        /**
         * If the user is heading to the logout route, continue
         * Else redirect the user to the redirect URL from the query param context
         */
        if (!pathname.includes(LOGOUT_ROUTE)) {
          window.location.replace(qp.redirect)
        }
      }
    } catch (error) {
      navigate(ERROR_ROUTE, { replace: true })
    }
  }

  // After user is authenticated through risk auth
  const handleRiskAuthComplete = () => {
    const { localStorageRedirectUrl } = getLocalStorageData()
    setIsAuthenticated(true)
    if (localStorageRedirectUrl) {
      window.location.replace(decodeURIComponent(localStorageRedirectUrl))
    } else {
      navigate(originalUri.current, { replace: true })
    }
  }

  // Send the MFA code
  const challengeSession = async (
    factor?: OtpFactor,
    intention?: string | null
  ): Promise<OtpResponse> => {
    try {
      const response = await riskAuth.challengeSession(
        authState?.idToken?.idToken ?? '',
        factor,
        intention
      )
      if (response.success) {
        setSelectedFactor(factor)
      }
      return response
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong. Please try again',
      }
    }
  }

  // Verify the MFA code
  const verifySession = async (
    factorId: string,
    passCode: string,
    intention?: string | null
  ): Promise<OtpResponse> => {
    try {
      const response = await riskAuth.verifySession(
        authState?.idToken?.idToken ?? '',
        factorId,
        passCode,
        intention
      )
      if (response.success) {
        handleRiskAuthComplete()
      }
      return response
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong, please try again.',
      }
    }
  }

  // Resend the code
  const otpResend = async (intention?: string | null): Promise<OtpResponse> => {
    try {
      if (!selectedFactor) {
        return {
          success: false,
          error: 'Something went wrong. Please try again.',
        }
      }
      const response = await challengeSession(selectedFactor, intention)
      return response
    } catch (error) {
      return {
        success: false,
        error: 'Something went wrong. Please try again',
      }
    }
  }

  // END METHODS

  return (
    <RiskAuthContext.Provider
      value={{
        isAuthenticated,
        challengeSession,
        verifySession,
        otpResend,
        getLocalStorageData,
        factors,
      }}
    >
      {children}
    </RiskAuthContext.Provider>
  )
}
