import { ExternalLink, MLTextDesktopIcon, MLTextMobileIcon } from 'components'
import { HelpIcon } from '../../../../components/shared/icons'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent } from 'models'
import { ML_HELP_LINK } from 'utilities'
import { WorkingDesk } from '../../../../components/shared/icons/WorkingDesk'

interface Props {
  analyticsPage: string
}

export function LeftUIContainer({ analyticsPage }: Props) {
  const { isDesktop } = useBreakpoint()
  return (
    <div className="col-span-4 grid grid-cols-[1fr_min-content] bg-green lg:max-w-[405px] lg:grid-cols-1 lg:grid-rows-[1fr_min-content] lg:bg-ink-wash">
      <MLTextDesktopIcon className="hidden fill-green pb-extra-large pr-36 pl-24 lg:block" />
      <MLTextMobileIcon className="mx-auto my-4 fill-white lg:hidden" />
      <WorkingDesk className="hidden h-full fill-green pb-extra-large pt-larger pl-12 lg:block" />
      <div
        className={`mb-6 grid h-fit w-full gap-[40px] pl-extra-large pr-extra-medium text-white ${
          !isDesktop ? 'hidden' : ''
        }`}
      ></div>
      <ExternalLink
        to={ML_HELP_LINK}
        className="pr-4 hover:underline"
        title="Get help"
        newTab
        analyticsEventName={`${analyticsPage}: ${AnalyticsEvent.Help}`}
      >
        <HelpIcon
          className={`${isDesktop ? 'hidden' : ''} h-full fill-white`}
        />
      </ExternalLink>
    </div>
  )
}
