import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Layout } from 'components'
import { OPEN_ID_CONFIG } from 'config/openid.config'
import {
  AnalyticsProvider,
  RiskAuthProvider,
  QueryParamContextProvider,
} from 'contexts'
import { useNavigate } from 'react-router-dom'
import { RiskAuthService, TmxService } from 'services'
import { CALLBACK_ROUTE, getEnvValue, HOME_ROUTE } from 'utilities'
import { CookieManager } from 'utilities/CookieManager'
import './App.scss'
import { UrlRoutes } from './routes/Routes'
import * as FullStory from '@fullstory/browser'

FullStory.init({
  orgId: getEnvValue('REACT_APP_FULLSTORY_ORG_ID'),
  devMode: getEnvValue('REACT_APP_FULLSTORY_PROD_LOGGING') !== 'true',
})

const oktaAuth = new OktaAuth(OPEN_ID_CONFIG.oidc)

export default function App() {
  const navigate = useNavigate()

  async function restoreOriginalUri(_oktaAuth: OktaAuth, originalUri: string) {
    navigate(
      toRelativeUrl(originalUri ?? CALLBACK_ROUTE, window.location.origin)
    )
  }

  const redirectToLogin = async () => {
    navigate(HOME_ROUTE, { replace: true })
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={redirectToLogin}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AnalyticsProvider>
        <QueryParamContextProvider>
          <RiskAuthProvider riskAuth={RiskAuthService} tmxService={TmxService}>
            <CookieManager />
            <Layout>
              <UrlRoutes />
            </Layout>
          </RiskAuthProvider>
        </QueryParamContextProvider>
      </AnalyticsProvider>
    </Security>
  )
}
