import { notifyBugsnag } from 'utilities'

export class TmxServiceError extends Error {
  constructor(message: string, e?: Error) {
    super(message)
    this.name = 'TmxServiceError'
    if (e) {
      this.message += `:${e.message}`
      this.stack = e.stack
    }
  }
}

export const handleError = (message: string): void => {
  const error = new TmxServiceError(message)
  notifyBugsnag({ error, name: 'TmxService' })
}
