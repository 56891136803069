import { useState } from 'react'
import { notifyBugsnag } from 'utilities'

/**
 * Sync state to local storage so that it persists through a page refresh.
 * Usage is similar to useState except we pass in a local storage key so that
 * we can default to that value on page load instead of the specified initial
 * value. Since the local storage API isn't available in server-rendering
 * environments, we check that typeof window !== "undefined" to make SSR and
 * SSG work properly.
 *
 * @param key
 * @param initialValue
 * @returns
 */
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item
        ? typeof item === 'object'
          ? JSON.parse(item)
          : item
        : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      notifyBugsnag({ error: error as Error, name: 'LocalStorage-GetItem' })
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        const finalValue =
          typeof valueToStore === 'object'
            ? JSON.stringify(valueToStore)
            : (valueToStore as string)
        window.localStorage.setItem(key, finalValue)
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
      notifyBugsnag({ error: error as Error, name: 'LocalStorage-SetValue' })
    }
  }
  return [storedValue, setValue] as const
}
