import { LoginCallback } from '@okta/okta-react'
import { Loading, LoginPassword, Playground } from 'components'
import {
  CreateNewPasswordForm,
  SelectFactorTypeForm,
  VerifyPassCodeForm,
} from 'components/mfa/forgot-password/forms'
import { ChangePasswordContextProvider } from 'contexts'
import { LoginMfaContextProvider } from 'contexts/LoginMfaContext'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPassword, SetPassword, LoginUsername, LoginMfa } from 'routes'
import {
  AUTHORIZE_ROUTE,
  CALLBACK_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGOUT_ROUTE,
  ERROR_ROUTE,
  MAINTENANCE_ROUTE,
  getEnvValue,
  LOGIN_MFA_ROUTE,
  SET_PASSWORD_ROUTE,
} from 'utilities'
import { ErrorPage } from './ErrorPage'
import { Logout } from './Logout'
import { MaintenancePage } from './MaintenancePage'
import { PageNotFound } from './PageNotFound'

function MaintenanceRoutes() {
  return (
    <Routes>
      <Route path={MAINTENANCE_ROUTE} element={<MaintenancePage />} />
      <Route path="*" element={<Navigate to={MAINTENANCE_ROUTE} />} />
    </Routes>
  )
}

function ApplicationRoutes() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} element={<LoginUsername />} />
      <Route path={AUTHORIZE_ROUTE} element={<LoginPassword />} />
      <Route
        path={CALLBACK_ROUTE}
        element={
          <Loading>
            <LoginCallback />
          </Loading>
        }
      />
      <Route path={LOGOUT_ROUTE} element={<Logout />} />
      <Route path={ERROR_ROUTE} element={<ErrorPage />} />
      <Route
        path={FORGOT_PASSWORD_ROUTE}
        element={
          <ChangePasswordContextProvider>
            <ForgotPassword />
          </ChangePasswordContextProvider>
        }
      >
        <Route path="1" element={<SelectFactorTypeForm />} />
        <Route path="2" element={<VerifyPassCodeForm />} />
        <Route path="3" element={<CreateNewPasswordForm />} />
        <Route path="4" element={null} />
      </Route>
      <Route
        path={SET_PASSWORD_ROUTE}
        element={
          <ChangePasswordContextProvider>
            <SetPassword />
          </ChangePasswordContextProvider>
        }
      >
        <Route path="1" element={<SelectFactorTypeForm />} />
        <Route path="2" element={<VerifyPassCodeForm />} />
        <Route path="3" element={<CreateNewPasswordForm />} />
        <Route path="4" element={null} />
      </Route>
      {getEnvValue('REACT_APP_ENV') !== 'PRODUCTION' && (
        <Route path="playground" element={<Playground />} />
      )}
      <Route
        path={LOGIN_MFA_ROUTE}
        element={
          <LoginMfaContextProvider>
            <LoginMfa />
          </LoginMfaContextProvider>
        }
      />
      <Route path={MAINTENANCE_ROUTE} element={<MaintenancePage />} />
      {/* 👇️ only match this when no other routes match */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export function UrlRoutes() {
  const { enableLoginMaintenance } = useFlags()
  if (typeof enableLoginMaintenance === 'boolean') {
    if (enableLoginMaintenance) {
      return <MaintenanceRoutes />
    }
    return <ApplicationRoutes />
  }
  return null
}
