import { PrimaryActionButton } from 'components'

interface LoginActionButtonsType {
  isSuccess: boolean
  isSubmitting: boolean
  onCancel: () => void
  disableSubmit?: boolean
  submitButtonText?: string
  className?: string
}

export function LoginActionButtons({
  isSuccess,
  isSubmitting,
  disableSubmit,
  submitButtonText,
  className,
}: LoginActionButtonsType) {
  return (
    <PrimaryActionButton
      defaultText={submitButtonText ?? 'Log In'}
      type="submit"
      actingText="Submitting..."
      isSuccess={isSuccess}
      successText="Success!"
      isActing={isSubmitting}
      disabled={disableSubmit}
      className={className}
    />
  )
}
