import { notifyBugsnag } from 'utilities'
import {
  EvaluateResponse,
  IRiskAuthService,
  OtpFactor,
  OtpResponse,
} from './types'

const evaluateSession = async (
  threatMetrixSessionId: string | null,
  intention?: string | null,
  jwt?: string
): Promise<EvaluateResponse> => {
  try {
    const response = await fetch(
      `${process.env
        .REACT_APP_RISK_AUTH_SERVICE_URL!}/v1/auth/sessions/evaluate`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          threatMetrixSessionId,
          intention,
          jwt,
        }),
      }
    )

    if (response.status >= 500) {
      throw Error(response.json.toString())
    }
    const body = await response.json()
    return body
  } catch (e) {
    notifyBugsnag({
      error: e as Error,
      name: 'RiskAuthService-EvaluateSession',
    })
    throw e
  }
}

const challengeSession = async (
  jwt: string,
  factor: OtpFactor | undefined,
  intention?: string | null
): Promise<OtpResponse> => {
  try {
    await fetch(
      `${process.env
        .REACT_APP_RISK_AUTH_SERVICE_URL!}/v1/auth/sessions/challenges`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          factorId: factor?.id,
          intention,
        }),
      }
    )
    return {
      success: true,
    }
  } catch (e) {
    notifyBugsnag({
      error: e as Error,
      name: 'RiskAuthService-ChallengeSession',
    })
    return {
      success: false,
      error: 'Something went wrong, please try again',
    }
  }
}

const verifySession = async (
  jwt: string,
  factorId: string,
  passCode: string,
  intention?: string | null
): Promise<OtpResponse> => {
  try {
    const response = await fetch(
      `${process.env
        .REACT_APP_RISK_AUTH_SERVICE_URL!}/v1/auth/sessions/challenges/verify`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          factorId,
          passCode,
          intention,
        }),
      }
    ).then(async (res) => await res.json())
    if (response.verificationOutcome === 'SUCCEEDED') {
      return {
        success: true,
      }
    }
    return {
      success: false,
      error: 'Code is incorrect. Please try again.',
    }
  } catch (e) {
    notifyBugsnag({ error: e as Error, name: 'RiskAuthService-VerifySession' })
    return {
      success: false,
      error: 'Something went wrong, please try again',
    }
  }
}

export const RiskAuthService: IRiskAuthService = {
  evaluateSession,
  challengeSession,
  verifySession,
}
