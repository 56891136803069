import { AnalyticsContext } from 'contexts'
import { useContext } from 'react'
import * as FullStory from '@fullstory/browser'
import { AnalyticsEvent, TrackEventMetadata } from 'models'
import Bugsnag from '@bugsnag/js'
import { notifyBugsnag } from 'utilities'
// import * as braze from '@braze/web-sdk'

export const useAnalytics = () => {
  const analytics = useContext(AnalyticsContext)
  if (!analytics) {
    throw new Error('Context used outside of its Provider!')
  }

  /** Identifies the user to all relevant analytics trackers
   * istanbul ignore next
   */
  function identify(userId: string, email?: string) {
    try {
      void analytics.identify(userId, { email })
      FullStory.identify(userId, { email })
      Bugsnag.setUser(userId, email)
      // braze.changeUser(ecsId)
    } catch (e) {
      notifyError(e, 'Analytics hook could not record identify event')
    }
  }

  /**
   * Resets any identify calls in analytics trackers, causing them to usually
   * generate a new anonymousId. Useful for signout.
   */
  function unidentify() {
    try {
      void analytics.reset()
      FullStory.restart()
      Bugsnag.setUser(undefined, undefined)
    } catch (e) {
      notifyError(e, 'Analytics hook could not record unidentify event')
    }
  }

  /** Notifies error trackers of a handled exception. Optionally supports setting an innerError */
  function notifyError(
    /** The original error thrown. Preferably an Error object, but will attempt to infer any commonly thrown
     * error type at runtime, falling back to logging in the console if not.
     */
    err: unknown,
    /** An optional message that will be prepended to the error message before sending. Keeeps stack trace intact if provided.  */
    message?: string
  ) {
    // JavaScript allows errors to be anything. Trackers demand something more specific
    // This code attempts to cast almost any kind of thrown error into something the trackers can consume.
    let errOut: Error | undefined
    if (err instanceof Error) {
      errOut = err
    } else if (typeof err === 'string') {
      errOut = new Error(err)
    } else if (err && typeof err === 'object') {
      errOut = new Error()
      // if a message prop is provided, and is a string, it can be the error message!
      const errObj = err as { [key: string]: unknown }
      if (errObj.message && typeof errObj.message === 'string') {
        errOut.message = errObj.message
      } else if (
        errObj.errorMessage &&
        typeof errObj.errorMessage === 'string'
      ) {
        errOut.message = errObj.erorMessage as string
      } else {
        errOut.message = 'Unknown error'
      }
      // same for name
      if (errObj.name && typeof errObj.name === 'string') {
        errOut.name = errObj.name
      } else if (errObj.errorClass && typeof errObj.errorClass) {
        errOut.name = errObj.className as string
      }
    } else if (typeof err === 'number') {
      errOut = new Error('Code ' + err.toString())
    } else {
      // worst case - don't know what this error is saying
      // at least create an error object so there's a stack trace
      errOut = new Error('Unknown error')
    }

    // prepend a message to the original error's message if requested
    if (message) {
      errOut.message = message + ': ' + errOut.message
    }
    // notify trackers
    notifyBugsnag({ error: errOut, name: 'AnalyticsTrackingError' })
  }

  /** Notifies all relevant trackers of an application event. This could be almost anything we want to make note of */
  function trackEvent(
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: TrackEventMetadata
  ) {
    try {
      void analytics.track(eventName, metadata)
      FullStory.event(eventName, metadata)
      Bugsnag.leaveBreadcrumb(eventName, metadata)
    } catch (err) {
      notifyError(err, 'Analytics hook could not record Segment track event')
    }
  }

  /** Notifies all relevant trackers of an application click event */
  function trackClickEvent(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: TrackEventMetadata
  ) {
    trackEvent(AnalyticsEvent.Click, metadata)
  }

  /** Notifies all relevant trackers of an application input event */
  function trackInputEvent(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata: TrackEventMetadata
  ) {
    trackEvent(AnalyticsEvent.Input, metadata)
  }

  /* track a page view */
  const page = (name: string, metadata?: TrackEventMetadata) => {
    try {
      void analytics.page(AnalyticsEvent.Page, name, metadata)
    } catch (e) {
      notifyError(e, 'Analytics hook could not record Segment page event')
    }
  }

  return {
    identify,
    unidentify,
    notifyError,
    page,
    trackClickEvent,
    trackEvent,
    trackInputEvent,
  }
}
