import {
  AnchorIcon,
  Header,
  LoginLink,
  PrimaryActionButton,
  TextAndLink,
} from 'components'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOME_ROUTE, ML_HELP_LINK } from 'utilities'

export function ErrorPage() {
  const { page } = useAnalytics()
  const navigate = useNavigate()
  useEffect(() => {
    page(`${AnalyticsPage.Login}: ${AnalyticsPage.Error}`)
  }, [])
  return (
    <section
      className={`grid-flow-cols h-screen grid-cols-1 overflow-scroll bg-haze-lightest lg:grid-rows-2`}
    >
      <Header bgColor="white" analyticsPage={AnalyticsEvent.Error} />
      <div className="mx-8 max-w-[726px] md:mx-auto">
        <div className="mx-auto flex justify-center py-6 md:pt-4">
          <AnchorIcon className="mx-8" />
        </div>
        <div>
          <h2 className="text-center">
            Sorry! Not your fault, we are having trouble logging you in.
          </h2>
          <TextAndLink
            text="Please retry logging in or send us an email to "
            external
            link={ML_HELP_LINK}
            className="pt-12 text-center text-long-form"
            linkText="get help."
            analyticsEventName={`${AnalyticsEvent.Error}: ${AnalyticsEvent.Help}`}
          />
        </div>
        <LoginLink
          onClick={() => navigate(HOME_ROUTE)}
          analyticsEventName={`${AnalyticsEvent.Error}: ${AnalyticsEvent.BackToLogin}`}
        >
          <PrimaryActionButton
            defaultText="Log In"
            type="submit"
            actingText="Submitting..."
            isSuccess={false}
            successText="Success!"
            isActing={false}
          />
        </LoginLink>
      </div>
    </section>
  )
}
