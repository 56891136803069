import { OktaResponseError } from 'models'
import { useEffect } from 'react'
import { notifyBugsnag } from 'utilities'
import { AuthError, DefaultAuthError } from './DefaultAuthError'
import { LoginLink } from './LoginLink'

/*
Okta Error Codes: https://developer.okta.com/docs/reference/error-codes/
 */

interface OktaTimedOutErrorMessageProps {
  onReset: () => void
}

const OktaTimedOutErrorMessage = ({
  onReset,
}: OktaTimedOutErrorMessageProps) => {
  return (
    <AuthError
      errors={
        <>
          Authentication timed out. Please{' '}
          <LoginLink onClick={onReset}>start over.</LoginLink>
        </>
      }
    />
  )
}

export const invalidOtpErrorMessage = 'Code is incorrect, please try again.'

const errorsHandledInFormTextInput = ['E0000068']
export const oktaErrorsToIgnoreInBugsnag = ['E0000004', 'E0000080']

export const notifyOktaAuthError = (error: OktaResponseError) => {
  const { errorCode } = error
  if (!oktaErrorsToIgnoreInBugsnag.includes(errorCode)) {
    notifyBugsnag({
      error,
      name: 'OktaAuthError',
      prefix: errorCode,
    })
  }
}

interface OktaErrorMessageProps {
  error: OktaResponseError
  onReset?: () => void
  className?: string
}

export function OktaErrorMessage({
  error,
  onReset,
  className = '',
}: OktaErrorMessageProps) {
  const { errorCode, errorSummary } = error
  useEffect(() => {
    notifyOktaAuthError(error)
  }, [error])

  // do not show any error messaging when we already show them in the text input
  if (errorsHandledInFormTextInput.includes(errorCode)) {
    return null
  }

  if (errorCode === 'E0000011' && onReset) {
    return <OktaTimedOutErrorMessage onReset={onReset} />
  }

  if (errorCode === 'E0000109') {
    return <AuthError errors={errorSummary} />
  }

  if (errorCode === 'E0000017') {
    return (
      <AuthError
        errors="Password reset failed. Please try again."
        className={className}
      />
    )
  }

  if (errorCode === 'E0000080') {
    return (
      <AuthError
        errors="This password has already been used. Please try again."
        className={className}
      />
    )
  }

  return <DefaultAuthError className={className} />
}
