import { useMemo } from 'react'
import {
  RightArrow,
  FourIcon,
  OneIcon,
  ThreeIcon,
  TwoIcon,
  SuccessCircleIcon,
} from 'components'
import { MfaStep } from 'models'

interface Props {
  className?: string
  step: MfaStep
  activeStep: number
}

/**
 * This component renders a step icon, the step name, and conditionally
 * an arrow icon. It applies conditional logic to render an arrow and
 * style the icon and text based on whether the step is completed, active,
 * or in neither state.
 *
 * @param step the current step object
 * @param activeStep the index of which step is currently active
 * @returns
 */
export function Step({ className = '', step, activeStep }: Props) {
  const renderStep = useMemo(() => {
    const isActive = step.id === activeStep
    let stepIcon = <FourIcon isActive={isActive} />
    switch (step.id) {
      case 0:
        stepIcon = <OneIcon isActive={isActive} />
        break
      case 1:
        stepIcon = <TwoIcon isActive={isActive} />
        break
      case 2:
        stepIcon = <ThreeIcon isActive={isActive} />
        break
    }
    return (
      <div className={`grid grid-cols-[26px_1fr_20px] gap-medium ${className}`}>
        {step.isCompleted ? <SuccessCircleIcon /> : stepIcon}
        {step.name}
        {isActive && <RightArrow className="justify-self-end" />}
      </div>
    )
  }, [activeStep, step])
  return renderStep
}
