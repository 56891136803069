interface Props {
  className?: string
  stroke?: string
}

export function RightArrow({ className = '', stroke = 'blue' }: Props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.0581055 10H16.898M16.898 10L9.32006 1M16.898 10L9.32006 19"
        stroke={stroke}
        strokeWidth="3"
      />
    </svg>
  )
}
