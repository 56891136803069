import { useAnalytics } from 'hooks'
import useBreakpoint from 'hooks/useBreakpoint'
import { BackIcon } from './icons'

interface Props {
  className?: string
  analyticsEventName?: string
  onClick?: () => any
}

export function Back({
  className = '',
  onClick,
  analyticsEventName = '',
}: Props) {
  const { isDesktop } = useBreakpoint()
  const { trackClickEvent } = useAnalytics()
  /**
   * If an onClick function was passed, call the function
   * Else navigate back in the browser history
   */
  const handleClick = () => {
    if (analyticsEventName) {
      trackClickEvent({
        event: analyticsEventName,
      })
    }
    if (typeof onClick === 'function') {
      onClick()
    } else {
      history.back()
    }
  }
  return (
    <button
      className={`align-center my-auto grid grid-cols-[20px_1fr] items-center text-grey hover:underline ${className}`}
      onClick={handleClick}
      title={!isDesktop ? 'Go Back' : ''}
    >
      <BackIcon className="float-left fill-grey" /> {isDesktop ? 'Back' : ''}
    </button>
  )
}
