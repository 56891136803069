import { RightArrow } from 'components'
import { useMemo } from 'react'

interface Props {
  id: string
  type: string
  value: string
  className?: string
  register: any
  label: string
  name: string
  currentValue: string
  subText?: string
  disabled: boolean
  onChange?: any
}

export function RadioInput({
  className = '',
  id,
  type,
  value,
  label,
  name,
  register,
  currentValue,
  subText = '',
  disabled,
  onChange,
}: Props) {
  const isActive = useMemo(() => {
    return currentValue === value
  }, [currentValue])
  return (
    <>
      <label
        htmlFor={id}
        className={`grid h-[126px] w-full grid-cols-[min-content_1fr] rounded-xl border-2 p-small text-center hover:cursor-pointer md:row-start-1 ${
          isActive
            ? 'border-blue bg-[#ECF4FF] text-blue'
            : 'text-black shadow-gray-50'
        }`}
      >
        <input
          {...register}
          id={id}
          name={name}
          label={label}
          type={type}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className={`h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 ${className}`}
        />
        <div className="grid grid-cols-2 gap-2 self-center">
          <span className="justify-self-end">{label}</span>
          <RightArrow
            className="justify-self-start"
            stroke={`${isActive ? 'blue' : 'black'}`}
          />
        </div>
      </label>
      <p className="text-m-small-body">{subText}</p>
    </>
  )
}
