import { InputOption } from 'models'
import { RadioInput } from './RadioInput'

interface Props {
  className?: string
  fieldName: string
  register: any
  currentValue: string
  options: InputOption[]
  disabled?: boolean
  onChange?: any
}

export function HookFormRadioGroup({
  className = '',
  fieldName,
  register,
  currentValue = '',
  options,
  disabled = false,
  onChange,
}: Props) {
  return (
    <fieldset id={`${fieldName}-group`} className={className}>
      {options.map((option, i) => (
        <RadioInput
          key={`radio-input-${fieldName}-${i}`}
          name={fieldName}
          currentValue={currentValue}
          register={register(fieldName, { required: option.required })}
          label={option.label}
          id={option.id}
          type="radio"
          value={option.value}
          subText={option.subText}
          disabled={disabled}
          onChange={onChange}
        />
      ))}
    </fieldset>
  )
}
