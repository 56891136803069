interface Props {
  className?: string
}

export function SuccessCircleIcon({ className = '', ...rest }: Props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <circle cx="12.5" cy="13" r="12" fill="#007459" stroke="#005EF6" />
      <path
        d="M4 13L6 10.8333L10 15.1667L18 6.5L20 8.66667L10 19.5L4 13Z"
        fill="white"
      />
    </svg>
  )
}
