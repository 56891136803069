import { OtpFactorType } from './otp-factor.type'

export interface TrackEventMetadata {
  [key: string]: any
}

export enum AnalyticsPage {
  Authenticated = 'Authenticated Redirect',
  EnterEmail = 'Enter Email',
  EnterPassword = 'Enter Password',
  Error = 'Technical Error Page',
  Loading = 'Loading',
  Login = 'Log In',
  Logout = 'Log Out',
  Maintenance = 'Maintenance',
  MfaCall = 'MFA Call Input',
  MfaRequired = 'MFA Required',
  MfaText = 'MFA Text Input',
  PageNotFound = 'Page Not Found',
  ResetPassword = 'Reset Password',
  LoginMfa = 'MFA Log in',
  Success = 'Success',
}

export enum AnalyticsEvent {
  Api = 'API Request',
  Back = 'Back',
  BackToLogin = 'Back to Log In',
  Call = 'Call Me',
  CallInstead = 'Call Me Instead',
  ChangeAccounts = 'Change Accounts',
  Click = 'Click',
  ConfirmPassword = 'Confirm Password',
  ContactUs = 'Contact Us',
  ContinueToLogin = 'Continue to Log In',
  EnterCode = 'Enter Code',
  EnterPassword = 'Enter Password',
  Error = 'Error',
  ErrorHeader = 'Error Header',
  ForgotPassword = 'Forgot Your Password',
  Help = 'Get Help',
  Input = 'Input',
  InvalidLogin = 'Invalid Email or Invalid Password',
  ConditionsOfUse = 'Conditions of Use',
  Login = 'Log In',
  MfaSelection = 'MFA Selection',
  Page = 'Page Viewed',
  PrivacyPolicy = 'Privacy Policy',
  RedirectToError = 'Redirect to Error page',
  ResendCode = 'Resend Code',
  SendCode = 'Send Code',
  Submit = 'Submit',
  SubmitEmail = 'Submit Email',
  SubmitPassword = 'Submit Password',
  Text = 'Text Me',
  TextInstead = 'Text Me Instead',
}

export enum AnalyticsError {
  Auth = 'Invalid Auth Token',
  MissingRedirect = 'Missing valid redirect URL',
}

/**
 * Construct the pageName within the forgot password flow based on
 * AnalyticsPage enum values, the currently active step, the
 * isOnSubstep boolean, and the current OTP factor
 * @param step
 * @param isOnSubStep
 * @param factorType 'sms' or 'call'
 * @returns the pageName for analytics logging
 */
export function getForgotPasswordPageName(
  step: number,
  isOnSubStep = false,
  factorType: OtpFactorType = 'sms'
) {
  let pageName = `${AnalyticsPage.ResetPassword}`
  if (step === 0 && !isOnSubStep) pageName += `: ${AnalyticsPage.MfaRequired}`
  else if (step === 0 && isOnSubStep)
    pageName +=
      factorType === 'call'
        ? `: ${AnalyticsPage.MfaCall}`
        : `: ${AnalyticsPage.MfaText}`
  else if (step === 2) pageName += `: ${AnalyticsPage.Success}`
  return pageName
}
