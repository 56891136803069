import {
  ExternalLink,
  HelpIcon,
  MLTextDesktopIcon,
  MLTextMobileIcon,
} from 'components'
import { useChangePasswordContext } from 'contexts'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent } from 'models'
import { ML_HELP_LINK } from 'utilities'
import { Step } from '../Step'

export function StepContainer() {
  const { isDesktop } = useBreakpoint()
  const { activeStep, steps } = useChangePasswordContext()

  return (
    <div className="col-span-4 grid grid-cols-[1fr_min-content] bg-green lg:max-w-[525px] lg:grid-cols-1 lg:grid-rows-[min-content_1fr] lg:bg-ink-wash">
      <MLTextDesktopIcon className="hidden h-full fill-green pb-extra-large pt-larger pr-36 pl-24 lg:block" />
      <MLTextMobileIcon className="mx-auto my-4 fill-white lg:hidden" />
      <div
        className={`mb-6 grid h-fit w-full gap-[40px] pl-extra-large pr-extra-medium text-white ${
          !isDesktop ? 'hidden' : ''
        }`}
      >
        {steps.map((step, i) => (
          <Step
            className={`${step.id === activeStep ? 'active' : 'inactive'} ${
              step.isCompleted ? 'success' : ''
            }`}
            step={step}
            activeStep={activeStep}
            key={i}
          />
        ))}
      </div>
      <ExternalLink
        to={ML_HELP_LINK}
        className="pr-4 hover:underline"
        title="Get help"
        newTab
        analyticsEventName={AnalyticsEvent.Help}
      >
        <HelpIcon
          className={`${isDesktop ? 'hidden' : ''} h-full fill-white`}
        />
      </ExternalLink>
    </div>
  )
}
