import { Divider, TextAndLink } from 'components'
import Button from 'components/shared/Form/Button'
import { AUTHORIZE_ROUTE } from 'utilities'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import useBreakpoint from 'hooks/useBreakpoint'
import { useChangePasswordContext } from 'contexts'

interface Props {
  analyticsPage: string
  isSuccessPage: boolean
}

export function CtaContent({ analyticsPage, isSuccessPage }: Props) {
  const { isSetPassword } = useChangePasswordContext()
  const navigate = useNavigate()
  const { trackClickEvent } = useAnalytics()
  const { isDesktop } = useBreakpoint()
  return (
    <>
      {isSuccessPage ? (
        <Button
          className="mt-12"
          onClick={() => {
            trackClickEvent({
              event: `${analyticsPage} ${AnalyticsPage.Success}: ${AnalyticsEvent.ContinueToLogin}`,
            })
            navigate(AUTHORIZE_ROUTE)
          }}
        >
          Continue to Log In
        </Button>
      ) : (
        !isSetPassword && (
          <>
            <Divider
              text="Or"
              className={`${isDesktop ? `lg:text-center` : `hidden`}`}
            />
            <TextAndLink
              text={`Remember password?${' '}`}
              link={AUTHORIZE_ROUTE}
              className={`text-center ${isDesktop ? `` : `py-2`}`}
              linkText="Go back to log in"
              analyticsEventName={`${analyticsPage}: ${AnalyticsEvent.BackToLogin}`}
            />
          </>
        )
      )}
    </>
  )
}
