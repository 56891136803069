import { useForm } from 'react-hook-form'
import { AuthError, SubmitActionButtons, HookFormRadioGroup } from 'components'
import { Formik, Form, Field, FormikValues } from 'formik'
import { useMemo } from 'react'
import {
  FORGOT_PASSWORD_ROUTE,
  SET_PASSWORD_ROUTE,
  navigateToMissionLaneHome,
  notifyBugsnag,
} from 'utilities'
import { AnalyticsEvent, InputOption, OtpFactorType } from 'models'
import { useChangePasswordContext } from 'contexts'
import { useAnalytics } from 'hooks'
import { forgotPassword } from '../auth-functions'
import { useNavigate } from 'react-router-dom'

export function SelectFactorTypeForm() {
  const {
    analyticsEventName,
    auth,
    authError,
    isSubmitting,
    setAuthError,
    setAuthTransaction,
    setIsSubmitting,
    factorType,
    setFactorType,
    updateIsOnSubStep,
    username,
    isSetPassword,
  } = useChangePasswordContext()
  const { trackClickEvent } = useAnalytics()
  const navigate = useNavigate()
  const { register } = useForm()
  const canSubmit = useMemo(
    () => !!factorType && !isSubmitting,
    [factorType, isSubmitting]
  )
  const radioOptions: InputOption[] = [
    {
      id: 'field-call',
      label: 'Call me',
      value: 'call',
      required: true,
    },
    {
      id: 'field-text',
      label: 'Text me',
      value: 'sms',
      required: true,
      subText: 'Messages and data rates may apply',
    },
  ]

  const handleFactorTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const multiFactorType = event.currentTarget.value as OtpFactorType
    setFactorType(multiFactorType)
    const factorSelected =
      multiFactorType === 'sms' ? AnalyticsEvent.Text : AnalyticsEvent.Call
    trackClickEvent({
      event: `${AnalyticsEvent.MfaSelection}: ${factorSelected}`,
    })
  }

  const handleFactorTypeSubmit = async (_: FormikValues) => {
    trackClickEvent({
      event: `${analyticsEventName}: ${AnalyticsEvent.SendCode}`,
    })
    setIsSubmitting(true)
    try {
      const transaction = await forgotPassword(auth, username, factorType)
      setAuthTransaction(transaction)
      updateIsOnSubStep(true)
      setIsSubmitting(false)
      setAuthError(undefined)
      navigate(
        `${isSetPassword ? SET_PASSWORD_ROUTE : FORGOT_PASSWORD_ROUTE}/2`
      )
    } catch (error) {
      setAuthError(error)
      setIsSubmitting(false)
      notifyBugsnag({ error: error as Error, name: 'ForgotPassword' })
    }
  }
  return (
    <Formik onSubmit={handleFactorTypeSubmit} initialValues={{ factorType }}>
      <Form className="grid grid-cols-2 md:grid-cols-4 lg:gap-6">
        <Field
          as={HookFormRadioGroup}
          currentValue={factorType}
          disabled={isSubmitting}
          register={register}
          fieldName="factorType"
          options={radioOptions}
          onChange={handleFactorTypeChange}
          className="col-span-4 grid grid-cols-1 gap-2 md:row-start-2 md:grid-cols-2 lg:gap-5"
        />
        {authError && (
          <AuthError
            className="col-span-4 row-start-3"
            errors={authError.message || 'Invalid options. Please try again.'}
          />
        )}
        <SubmitActionButtons
          disableSubmit={!canSubmit}
          submitButtonText={isSubmitting ? 'Sending...' : 'Send Code'}
          onCancel={navigateToMissionLaneHome}
          className="col-span-4 row-start-4"
        />
      </Form>
    </Formik>
  )
}
