import { useLoginMfaContext } from 'contexts'

export function ResendCode() {
  const { onCall, onText, onResendCode, factorType } = useLoginMfaContext()
  return (
    <span className="col-span-4 row-start-3">
      <button className="link" onClick={onResendCode}>
        Resend code
      </button>{' '}
      available in 30 seconds or{' '}
      {factorType === 'sms' && (
        <button className="link" onClick={onCall}>
          call me instead
        </button>
      )}
      {factorType === 'call' && (
        <button className="link" onClick={onText}>
          text me instead
        </button>
      )}
    </span>
  )
}
