import { AuthError, SubmitActionButtons } from 'components'
import { Formik, Form } from 'formik'
import React, { useMemo, useRef, ClipboardEvent } from 'react'
import { navigateToMissionLaneHome } from 'utilities'
import { StepProps } from '../../shared/step-props.interface'
import { useLoginMfaContext } from 'contexts/LoginMfaContext'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent } from 'models'
import { ResendCode } from 'components/mfa/login/ResendCode'

export function VerifyPassCodeForm({ onSubmit }: StepProps) {
  const {
    isSubmitting,
    authError,
    setAuthError,
    factorType,
    verifyCode,
    setVerifyCode,
    analyticsEventName,
  } = useLoginMfaContext()
  const { trackInputEvent } = useAnalytics()
  const codeLength = factorType === 'sms' ? 6 : 5
  const canSubmit = useMemo(
    () => !isSubmitting && verifyCode.length === codeLength,
    [isSubmitting, verifyCode]
  )
  const char1Ref = useRef<HTMLInputElement>(null)
  const char2Ref = useRef<HTMLInputElement>(null)
  const char3Ref = useRef<HTMLInputElement>(null)
  const char4Ref = useRef<HTMLInputElement>(null)
  const char5Ref = useRef<HTMLInputElement>(null)
  const char6Ref = useRef<HTMLInputElement>(null)

  /**
   * Update focus to the previous input if this input value was removed
   * Disable and clear out remaining inputs after the current input
   * @param index
   */
  const handleDelete = (index: string) => {
    if (parseInt(index, 10) !== 0) {
      const prevInput = document.querySelector<HTMLInputElement>(
        `input[name=char-${parseInt(index, 10) - 1}]`
      )
      if (prevInput) prevInput.focus()
    }
    for (let i = +index + 1; i < codeLength + 1; i++) {
      const input = document.querySelector<HTMLInputElement>(
        `input[name=char-${i}]`
      )
      if (input) {
        input.value = ''
        input.disabled = true
      }
    }
  }

  const getValFromRef = (ref: any): string => {
    return ref?.current?.value ?? ''
  }

  const setUpdatedCodeFromRefs = (): void => {
    const updatedCode =
      getValFromRef(char1Ref) +
      getValFromRef(char2Ref) +
      getValFromRef(char3Ref) +
      getValFromRef(char4Ref) +
      getValFromRef(char5Ref) +
      getValFromRef(char6Ref)
    setVerifyCode(updatedCode)
  }

  // Enable & focus on the submit button after updating the last input
  const enableAndFocusSubmitButton = (): void => {
    const submitButton = document.querySelector<HTMLButtonElement>(
      'button.verify-pass-code-submit'
    )
    if (submitButton) {
      submitButton.disabled = false
      submitButton.focus()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackInputEvent({
      event: `${analyticsEventName}: ${AnalyticsEvent.EnterCode}`,
    })
    // Clear out any auth errors on change
    setAuthError(undefined)

    const { maxLength, value, name } = event.target
    const [, fieldIndex] = name.split('-')

    // Update focus to the next input if the field's maxLength has been met
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < codeLength) {
        const nextInput = document.querySelector<HTMLInputElement>(
          `input[name=char-${parseInt(fieldIndex, 10) + 1}]`
        )
        if (nextInput) {
          nextInput.disabled = false
          nextInput.focus()
        }
      } else {
        enableAndFocusSubmitButton()
      }
    } else {
      handleDelete(fieldIndex)
    }

    // Construct updatedCode from input refs and set in state
    setUpdatedCodeFromRefs()
  }

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedValue = event.clipboardData.getData('text')
    const splitValues = pastedValue.split('')

    /* Paste individual values until reaching the last input based on codeLength */
    for (let i = 1; i < codeLength + 1; i++) {
      const input = document.querySelector<HTMLInputElement>(
        `input[name=char-${i}]`
      )
      const nextPastedValue =
        splitValues.length > i - 1 ? splitValues[i - 1] : ''
      if (input && nextPastedValue) {
        input.value = nextPastedValue
        input.disabled = false
      }
    }
    const nextInputAfterPaste = document.querySelector<HTMLInputElement>(
      `input[name=char-${pastedValue.length + 1}]`
    )
    if (nextInputAfterPaste) {
      nextInputAfterPaste.disabled = false
      nextInputAfterPaste.focus()
    } else {
      enableAndFocusSubmitButton()
    }
    setUpdatedCodeFromRefs()
  }

  /**
   * Strip any non-numeric characters from input value and
   * do not allow a user to enter more than 1 character per
   * input
   * @param e input event
   */
  const handleInput = (e: any) => {
    e.currentTarget.value = e.currentTarget.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
    const val = e.currentTarget.value
    e.currentTarget.value =
      val.length > 1 ? val.substring(val.length - 1, val.length) : val
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={{}}>
      <Form>
        <label htmlFor="verifyCode">Verification Code</label>
        <fieldset
          className={`grid grid-flow-col-dense justify-start gap-3 pt-1 pb-4 md:gap-6 lg:pt-2 ${
            authError ? 'input-error' : ''
          }`}
          id="verifyCode"
        >
          <input
            id="char1"
            name="char-1"
            type="number"
            onChange={handleChange}
            onInput={handleInput}
            onPaste={handlePaste}
            maxLength={1}
            autoFocus={true}
            ref={char1Ref}
            className="code-single-input"
          />
          <input
            id="char2"
            name="char-2"
            type="number"
            onChange={handleChange}
            onInput={handleInput}
            onPaste={handlePaste}
            maxLength={1}
            ref={char2Ref}
            className="code-single-input"
            disabled={!char1Ref?.current?.value}
          />
          <input
            id="char3"
            name="char-3"
            type="number"
            onChange={handleChange}
            onInput={handleInput}
            onPaste={handlePaste}
            maxLength={1}
            ref={char3Ref}
            className="code-single-input"
            disabled={!char2Ref?.current?.value}
          />
          <input
            id="char4"
            name="char-4"
            type="number"
            onChange={handleChange}
            onInput={handleInput}
            onPaste={handlePaste}
            maxLength={1}
            ref={char4Ref}
            className="code-single-input"
            disabled={!char3Ref?.current?.value}
          />
          <input
            id="char5"
            name="char-5"
            type="number"
            onChange={handleChange}
            onInput={handleInput}
            onPaste={handlePaste}
            maxLength={1}
            ref={char5Ref}
            className="code-single-input"
            disabled={!char4Ref?.current?.value}
          />
          {factorType === 'sms' && (
            <input
              id="char6"
              name="char-6"
              type="number"
              onChange={handleChange}
              onInput={handleInput}
              onPaste={handlePaste}
              maxLength={1}
              ref={char6Ref}
              className="code-single-input"
              disabled={!char5Ref?.current?.value}
            />
          )}
        </fieldset>
        {authError && (
          <AuthError
            className="col-span-4 row-start-2 pr-2 md:col-span-2"
            errors={authError.message}
          />
        )}
        <ResendCode />
        <SubmitActionButtons
          disableSubmit={!canSubmit}
          submitButtonText={isSubmitting ? 'Verifying...' : 'Verify'}
          onCancel={navigateToMissionLaneHome}
          className="verify-pass-code-submit col-span-4 row-start-4"
        />
      </Form>
    </Formik>
  )
}
