interface Props {
  isActive?: boolean
}

export function TwoIcon({ isActive }: Props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="13"
        r={isActive ? '11.5' : '12'}
        className={isActive ? 'stroke-blue stroke-[2px]' : 'stroke-grey'}
      />
      <path
        d={
          isActive
            ? 'M15.468 9.984C15.468 8.472 14.352 7.644 12.612 7.644C10.68 7.644 9.408 8.808 9.432 10.608H11.04C11.04 9.312 11.784 8.868 12.516 8.868C13.32 8.868 13.8 9.312 13.8 10.068C13.8 11.448 12.072 12.9 9.276 15.156V16.5H15.672V15.108H11.208C13.884 13.32 15.468 11.616 15.468 9.984Z'
            : 'M15.384 10.924C15.384 9.46 14.232 8.644 12.624 8.644C10.776 8.644 9.576 9.784 9.6 11.512H10.728C10.752 10.072 11.664 9.556 12.6 9.556C13.632 9.556 14.232 10.168 14.232 11.068C14.232 12.604 12.432 14.248 9.36 16.516V17.5H15.624V16.48H10.848C13.284 14.728 15.384 12.94 15.384 10.924Z'
        }
        className={isActive ? 'fill-blue' : 'fill-grey'}
      />
    </svg>
  )
}
