import { LoginActionButtons, OktaErrorMessage } from 'components/mfa/login'
import { TextField } from 'components/shared/Form/Formik'
import { useChangePasswordContext } from 'contexts'
import { Formik, Form } from 'formik'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FORGOT_PASSWORD_ROUTE,
  navigateToMissionLaneHome,
  notifyBugsnag,
  PASSWORD_INPUT_TYPE,
  PASSWORD_VALIDATION_MSG,
  PASSWORD_VALIDATION_SCHEMA,
  SET_PASSWORD_ROUTE,
} from 'utilities'
import { resetPassword } from '../auth-functions'

export function CreateNewPasswordForm() {
  const {
    analyticsEventName,
    authError,
    authTransaction,
    isSubmitting,
    setAuthError,
    setAuthTransaction,
    setIsSubmitting,
    updateActiveStep,
    updateIsOnSubStep,
    isSetPassword,
  } = useChangePasswordContext()
  const navigate = useNavigate()
  const { trackClickEvent, trackInputEvent } = useAnalytics()
  const [password, setPassword] = useState<string>('')
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)

  const handleConfirmPasswordChange = (
    _: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuthError(null)
    trackInputEvent({
      event: `${AnalyticsPage.ResetPassword}: ${AnalyticsEvent.ConfirmPassword}`,
    })
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthError(null)
    trackInputEvent({
      event: `${AnalyticsPage.ResetPassword}: ${AnalyticsEvent.EnterPassword}`,
    })
    setPassword(event.currentTarget.value)
    setIsPasswordValid(!!event.currentTarget.value)
  }

  const handleNewPasswordSubmit = async ({
    confirmPassword,
  }: {
    confirmPassword: string
  }) => {
    trackClickEvent({
      event: `${analyticsEventName}: ${AnalyticsEvent.SubmitPassword}`,
    })
    setIsSubmitting(true)
    try {
      const transaction = await resetPassword(authTransaction, confirmPassword)
      setAuthTransaction(transaction)
      updateIsOnSubStep(false)
      setIsSubmitting(false)
      setAuthError(undefined)
      updateActiveStep(2)
      navigate(
        `${isSetPassword ? SET_PASSWORD_ROUTE : FORGOT_PASSWORD_ROUTE}/4`
      )
    } catch (error) {
      setAuthError(error)
      setIsSubmitting(false)
      notifyBugsnag({
        error: error as Error,
        name: 'ForgotPassword-ResetPassword',
      })
    }
  }

  return (
    <Formik
      validationSchema={PASSWORD_VALIDATION_SCHEMA}
      onSubmit={handleNewPasswordSubmit}
      initialValues={{
        password,
        confirmPassword: '',
      }}
    >
      {({ status, isValid, values, touched }) => (
        <Form>
          <TextField
            id="password"
            name="password"
            label="Password"
            placeholder="Password"
            type={PASSWORD_INPUT_TYPE}
            inputProps={{
              autoFocus: true,
              onChange: handlePasswordChange,
            }}
            subText={PASSWORD_VALIDATION_MSG}
            fieldClassName="pb-4 lg:pb-larger"
            errorClassName="float-none mt-4"
            shouldShowValidCheck={touched.password}
          />
          {isPasswordValid && (
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type={PASSWORD_INPUT_TYPE}
              shouldShowValidCheck={touched.confirmPassword}
              inputProps={{
                onChange: handleConfirmPasswordChange,
              }}
              errorClassName="mt-4"
            />
          )}
          {!isPasswordValid && (
            <TextField
              id="confirmPasswordDisabled"
              name="confirmPasswordDisabled"
              label="Confirm Password"
              type={PASSWORD_INPUT_TYPE}
              inputProps={{
                disabled: true,
              }}
              errorClassName="mt-4"
            />
          )}
          {authError && (
            <OktaErrorMessage
              error={authError}
              className="col-span-4 row-start-3 mt-8"
            />
          )}
          <LoginActionButtons
            submitButtonText={
              isSubmitting ? 'Setting Password...' : 'Set New Password'
            }
            isSuccess={status === 'success' && !isSubmitting}
            disableSubmit={!isValid || !values.confirmPassword}
            onCancel={navigateToMissionLaneHome}
            isSubmitting={!!isSubmitting}
          />
        </Form>
      )}
    </Formik>
  )
}
