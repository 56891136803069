import { useAnalytics } from 'hooks'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsPage } from 'models'
import { useEffect } from 'react'
import { PuzzlePeopleIcon } from './icons'
import { LoadingProgressIndicator } from './LoadingProgressIndicator'
import { MobileHeader } from './MobileHeader'

interface Props {
  children?: React.ReactNode
  message?: string
}

export function Loading({ children, message = 'Logging you in...' }: Props) {
  const { isDesktop } = useBreakpoint()
  const { page } = useAnalytics()
  useEffect(() => {
    page(`${AnalyticsPage.Login}: ${AnalyticsPage.Loading}`)
  }, [])
  return (
    <>
      <MobileHeader />
      <section className="grid h-screen grid-flow-row bg-haze-lightest bg-mobileSwoosh bg-cover bg-left-bottom bg-no-repeat md:bg-loadingSwoosh md:bg-auto lg:bg-contain">
        <div className="mx-auto text-center md:m-auto">
          <PuzzlePeopleIcon className="md-16 mx-auto max-w-[248px] md:mb-24 lg:max-w-none" />
          <LoadingProgressIndicator
            message={message}
            width={isDesktop ? 400 : 300}
          />
          {children}
        </div>
      </section>
    </>
  )
}
