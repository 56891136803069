import { useLocalStorage, useRouterQuery } from 'hooks'
import { QueryParams } from 'models/query-params.interface'
import { createContext, useContext, useMemo } from 'react'
import {
  LOGIN_USERNAME_KEY,
  REDIRECT_QUERY_PARAM_KEY,
  setEmailQueryParam,
} from 'utilities'
import { getSafeUrl } from 'utilities/functions'

const QueryParamContext = createContext<
  QueryParams & { redirectActual: string | null }
>(getInitialValue())

export const useQueryParamContext = () => useContext(QueryParamContext)

interface Props {
  children: React.ReactNode
}

export const QueryParamContextProvider = ({ children }: Props) => {
  const redirectUrl = useRouterQuery(REDIRECT_QUERY_PARAM_KEY)
  const currentValue =
    redirectUrl ?? localStorage.getItem(REDIRECT_QUERY_PARAM_KEY)
  const safeUrl = getSafeUrl(currentValue)
  const [, setUrl] = useLocalStorage<string>(REDIRECT_QUERY_PARAM_KEY, safeUrl)
  useMemo(() => setUrl(safeUrl), [safeUrl])

  setEmailQueryParam()
  const url = localStorage.getItem(REDIRECT_QUERY_PARAM_KEY)
  const username = localStorage.getItem(LOGIN_USERNAME_KEY)

  return (
    <QueryParamContext.Provider
      value={{
        redirect: url ?? '',
        email: username ?? '',
        redirectActual: currentValue,
      }}
    >
      {children}
    </QueryParamContext.Provider>
  )
}

function getInitialValue(): QueryParams & { redirectActual: string | null } {
  return {
    redirect: '',
    email: '',
    redirectActual: '',
  }
}
