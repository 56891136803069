import { GenericFieldProps } from '../form.type'
import Input from '../../Input'
import FieldWrapper from './FieldWrapper'

interface TextFieldProps<T> extends GenericFieldProps<T, HTMLInputElement> {
  trimOnBlur?: boolean
  acceptSuggestionCallback?: Function
  type?: string
  errorClassName?: string
  shouldShowValidCheck?: boolean
}

const TextField = <T extends {}>({
  fieldClassName,
  errorClassName = '',
  id,
  name,
  label = '',
  inputProps,
  trimOnBlur = true,
  type = 'text',
  subText,
  placeholder,
  shouldShowValidCheck = false,
}: TextFieldProps<T>) => (
  <FieldWrapper<T, string>
    subText={subText}
    className={fieldClassName}
    name={name}
    label={label}
    placeholder={placeholder}
  >
    {({ value, onBlur, onChange, errors, setFieldValue }) => (
      <Input
        type={type}
        errorClassName={errorClassName}
        {...inputProps}
        onChange={(e) => {
          onChange(e)
          inputProps?.onChange?.(e)
        }}
        onBlur={(e) => {
          trimOnBlur && setFieldValue(name, value?.trim() ?? '')
          onBlur(e)
          inputProps?.onBlur?.(e)
        }}
        name={String(name)}
        id={String(id)}
        errors={errors}
        value={value ?? ''}
        placeholder={placeholder ?? ''}
        showValidCheck={
          !!value && shouldShowValidCheck && errors && errors.length === 0
        }
      />
    )}
  </FieldWrapper>
)

export default TextField
