import { useAuth } from 'hooks'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { COOKIE_ID_TOKEN_KEY } from './constants'

export const COOKIE_ATTRIBUTES: Cookies.CookieAttributes = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  sameSite: 'None',
  secure: true,
}

export function CookieManager() {
  const { authState } = useAuth()
  const idTokenObject = authState?.idToken

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      Cookies.remove(COOKIE_ID_TOKEN_KEY, COOKIE_ATTRIBUTES)
    }
  }, [authState?.isAuthenticated])

  useEffect(() => {
    if (idTokenObject?.idToken) {
      Cookies.set(COOKIE_ID_TOKEN_KEY, idTokenObject.idToken, COOKIE_ATTRIBUTES)
    }
  }, [idTokenObject?.idToken])

  return null
}
