export const areCookiesEnabled = () => {
  try {
    // Create cookie
    document.cookie = 'cookietest=1'
    const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    return cookiesEnabled
  } catch {
    return false
  }
}
