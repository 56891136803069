interface Props {
  className?: string
}

export function Swoosh({ className = '', ...rest }: Props) {
  return (
    <svg
      role="img"
      aria-hidden="true"
      width="969"
      height="901"
      viewBox="0 0 969 901"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g opacity="0.15" transform="rotate(-1) scale(1.685) translate(-20 -290)">
        <path
          d="M-24.3615 638.405C37.591 635.164 212.393 618.751 368.825 500.982C604.018 323.929 600.953 65.9141 599.865 30.6183C653.389 27.3122 706.913 23.9887 760.436 20.6478C760.502 67.3457 754.819 395.88 454.669 615.064C267.864 751.422 64.3268 774.379 -17.1792 779.531L-24.3615 638.405Z"
          fill="white"
        />
        <path
          d="M-21.9665 817.963C47.796 814.622 319.413 794.478 545.513 600.426C820.544 364.378 810.645 57.8439 808.173 10.0374C861.377 6.99789 914.581 3.95541 967.784 0.910115C969.968 59.0861 976.762 425.514 645.722 704.707C383.159 926.143 72.6287 953.916 -14.7842 959.09L-21.9665 817.963Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
