import { useChangePasswordContext } from 'contexts'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsPage } from 'models'
import { useMemo } from 'react'
import { CtaContent } from '../../forgot-password/container/CtaContent'
import { ForgotPasswordHeader } from './Header'
import { StepContainer } from './StepContainer'
import { TitleContent } from './TitleContent'

interface Props {
  children: React.ReactNode
}

/**
 * This component renders the shared layout container for the Forgot Password feature
 * including the steps (active or not), help link and text, dividers, and go back
 * buttons and links. It renders a different view based on which screen size the user
 * is on using the useBreakpoint hook.
 *
 * @param children the DOM elements to render as children of this component
 */
export function ChangePasswordLayout({ children }: Props) {
  const {
    activeStep,
    subtitle,
    title,
    mobileSubtitle,
    mobileTitle,
    isOnSubStep,
    factorType,
    analyticsEventName,
  } = useChangePasswordContext()
  const isSuccessPage = useMemo(() => activeStep === 2, [activeStep])
  /**
   * If the user is on the Verification code substep, conditionally update
   * the subtitle based on which factor type the user selected, 'call' or 'sms'
   */
  const updatedSubtitle =
    activeStep === 0 && isOnSubStep
      ? `${subtitle} ${
          factorType === 'sms' ? 'just texted you.' : 'provided over the phone.'
        }`
      : subtitle

  const { isDesktop } = useBreakpoint()
  const getTitle = isDesktop ? title : mobileTitle
  const getSubtitle = isDesktop ? updatedSubtitle : mobileSubtitle

  return (
    <section className="grid h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none">
      <StepContainer />
      <div className="lg:form-max-width col-span-8 justify-items-center lg:my-even-larger">
        <ForgotPasswordHeader
          activeStep={activeStep}
          isSuccessPage={isSuccessPage}
          title={getTitle}
          isOnSubStep={isOnSubStep}
          analyticsEventName={analyticsEventName}
        />
        <div className="form-max-width px-6 lg:px-0">
          <TitleContent
            isSuccessPage={isSuccessPage}
            subtitle={getSubtitle}
            title={getTitle}
          />
          {children}
          <CtaContent
            analyticsPage={AnalyticsPage.ResetPassword}
            isSuccessPage={isSuccessPage}
          />
        </div>
      </div>
    </section>
  )
}
