import { SuccessCloudIcon } from 'components'

interface Props {
  isSuccessPage: boolean
  subtitle: string
  title: string
}

export function TitleContent({ isSuccessPage, subtitle, title }: Props) {
  return (
    <div
      className={`lg:pb-extra-large ${isSuccessPage ? 'pt-7 text-center' : ''}`}
    >
      {isSuccessPage && <SuccessCloudIcon className="mx-auto mb-8 lg:mb-28" />}
      <h3
        className={`pb-3 lg:block ${
          isSuccessPage ? 'success block' : 'hidden'
        }`}
      >
        {title}
      </h3>
      {subtitle && (
        <p
          className={`pb-2 text-ink-light ${
            isSuccessPage ? 'text-long-form' : 'text-standard'
          }`}
        >
          {subtitle}
        </p>
      )}
    </div>
  )
}
