import { v4 as uuidv4 } from 'uuid'
import { ITmxService, ThreatMetrixClient } from './types'
import { handleError } from './errors'
import {
  HOME_ROUTE,
  TMX_BASE_SESSION_ID_KEY,
  TMX_SESSION_ID_KEY,
} from 'utilities/constants'

const getThreatMetrixClient = (): ThreatMetrixClient | undefined => {
  try {
    const tmxClient = window.threatmetrix
    if (!tmxClient) {
      handleError('Threatmetrix client not found')
    }
    return tmxClient
  } catch (e) {
    handleError('getThreatMetrixClient threw an exception.')
    return undefined
  }
}

export const setTmxBaseSessionId = (): void => {
  if (localStorage.getItem(TMX_BASE_SESSION_ID_KEY)) {
    return
  }
  const newTmxBaseSessionId = uuidv4()
  localStorage.setItem(TMX_BASE_SESSION_ID_KEY, newTmxBaseSessionId)
}

export const setTmxSessionId = (): void => {
  const tmxBaseSessionId = localStorage.getItem(TMX_BASE_SESSION_ID_KEY)
  if (!tmxBaseSessionId) {
    handleError(
      `${TMX_BASE_SESSION_ID_KEY} needs to be set to create a new ${TMX_BASE_SESSION_ID_KEY}`
    )
    return
  }
  const newTmxSessionId = `${tmxBaseSessionId}-${Date.now()}`
  sessionStorage.setItem(TMX_SESSION_ID_KEY, newTmxSessionId)
}

/*
Profile's the user's device
 */
const profileUser = (): string | null => {
  try {
    if (location.pathname !== HOME_ROUTE) {
      const currentTmxSessionId = sessionStorage.getItem(TMX_SESSION_ID_KEY)
      if (currentTmxSessionId) {
        return currentTmxSessionId
      }
    }

    setTmxBaseSessionId()
    setTmxSessionId()
    const newTmxSessionId = sessionStorage.getItem(TMX_SESSION_ID_KEY)
    if (!newTmxSessionId) {
      handleError(`${TMX_SESSION_ID_KEY} does not exist`)
      return null
    }

    const tmxClient = getThreatMetrixClient()
    tmxClient?.profile(
      process.env.REACT_APP_THREATMETRIX_PROFILE_DOMAIN,
      process.env.REACT_APP_THREATMETRIX_ORG_ID,
      newTmxSessionId
    )

    return newTmxSessionId
  } catch (e) {
    handleError('profileUser threw an exception.')
    return null
  }
}

export const TmxService: ITmxService = {
  profileUser,
}
