import useBreakpoint from 'hooks/useBreakpoint'

export function Playground() {
  /**
   * Here's an example of using the useBreakpoint hook
   * to display different things when on desktop vs. mobile
   *
   * Sometimes the tailwind utility classes are not enough to
   * fully handle the customization we want at different screen sizes.
   * When that's the case, use the useBreakpoint hook.
   */
  const { isDesktop } = useBreakpoint()
  return (
    <section className="mx-8 max-w-4xl pt-5 lg:mx-auto">
      <h1>Playground</h1>
      <div
        className={`py-5 text-center text-white ${
          isDesktop ? 'bg-green' : 'bg-red'
        }`}
      >
        I&apos;m just a div and I&apos;m{' '}
        {isDesktop ? <>green on desktop</> : <>red on mobile</>}
      </div>
    </section>
  )
}
