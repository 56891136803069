import { LoginMfaLayout } from '../components/mfa/login/mfa-container'
import {
  SelectFactorTypeForm,
  VerifyPassCodeForm,
} from '../components/mfa/login/forms'
import { useLoginMfaContext, useRiskAuthContext } from 'contexts'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, InputOption } from 'models'
import { getAuthError, notifyBugsnag } from 'utilities'
import { useEffect, useMemo } from 'react'
import { OtpFactor } from 'services'

/**
 * This component manages displaying the correct form/step in the Forgot Password flow
 * based on the activeStep from the Forgot Password context. It also handles the submit
 * logic for each form
 */
export function LoginMfa() {
  const {
    activeStep,
    updateActiveStep,
    updateIsOnSubStep,
    isOnSubStep,
    setIsSubmitting,
    setFactorType,
    verifyCode,
    analyticsPageName,
    analyticsEventName,
    factorType,
    setAuthError,
  } = useLoginMfaContext()
  const { challengeSession, verifySession, otpResend, factors } =
    useRiskAuthContext()

  const otpFactors: InputOption[] = useMemo(
    () =>
      factors
        .filter((factor) => factor.factorType !== 'email')
        .map((factor) => {
          return {
            id: factor.id,
            value: factor.id,
            label: factor.factorType === 'call' ? 'Call me' : 'Text me',
            required: true,
            subText:
              factor.factorType === 'sms'
                ? 'Messages and data rates may apply'
                : '',
          }
        }),
    [factors]
  )

  const onCall = async () => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.CallInstead}`,
    })
    const selectedFactor = factors?.find(
      (factor) => factor?.factorType === factorType
    )
    await challengeSession(selectedFactor)
  }

  const onText = async () => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.TextInstead}`,
    })
    const selectedFactor = factors?.find(
      (factor) => factor?.factorType === factorType
    )
    await challengeSession(selectedFactor)
  }

  const { page, trackClickEvent } = useAnalytics()
  useEffect(() => {
    page(analyticsPageName, { activeStep })
  }, [analyticsPageName, activeStep])

  const goToNextStep = async () => {
    updateIsOnSubStep(true)
    updateActiveStep(0)
  }
  const resetSubmit = () => {
    setIsSubmitting(false)
  }
  const handleFactorTypeSubmit = async (selectedFactorId: string) => {
    trackClickEvent({
      event: `${analyticsEventName}: ${AnalyticsEvent.SendCode}`,
    })
    setIsSubmitting(true)
    try {
      const selectedOtpFactor = factors?.find(
        (factor) => factor?.id === selectedFactorId
      ) as OtpFactor
      setFactorType(selectedOtpFactor.factorType)
      await challengeSession(selectedOtpFactor)
      updateIsOnSubStep(true)
      await goToNextStep()
      resetSubmit()
    } catch (error) {
      setIsSubmitting(false)
      notifyBugsnag({ error: error as Error, name: 'LoginMfa' })
    }
  }

  const onResendCode = async (event: any) => {
    event.preventDefault()
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.ResendCode}`,
    })
    try {
      await otpResend()
    } catch (err: any) {
      notifyBugsnag({ error: err as Error, name: 'LoginMfa-ResendCode' })
    }
  }

  const handlePassCodeSubmit = async () => {
    trackClickEvent({
      event: `${analyticsEventName}: ${AnalyticsEvent.Submit}`,
    })
    setIsSubmitting(true)
    try {
      const selectedFactorId =
        factors?.find((factor) => factor?.factorType === factorType)?.id ?? ''
      await verifySession(selectedFactorId, verifyCode)
    } catch (error: any) {
      const e = getAuthError(error)
      setIsSubmitting(false)
      setAuthError(e)
      notifyBugsnag({
        error: error as Error,
        name: 'LoginMfa-VerifyAuthCode',
      })
    }
  }

  return (
    <LoginMfaLayout>
      {activeStep === 0 && !isOnSubStep && otpFactors && (
        <SelectFactorTypeForm
          onSubmit={handleFactorTypeSubmit}
          options={otpFactors}
        />
      )}
      {activeStep === 0 && isOnSubStep && (
        <VerifyPassCodeForm
          onSubmit={handlePassCodeSubmit}
          onResendCode={onResendCode}
          onCall={onCall}
          onText={onText}
        />
      )}
    </LoginMfaLayout>
  )
}
