import { useLocalStorage, useRouterQuery } from 'hooks'
import { OktaResponseError } from 'models'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  EMAIL_QUERY_PARAM_KEY,
  LOGIN_USERNAME_KEY,
  VALID_REDIRECTS,
} from './constants'

export function isOktaError(e: unknown): e is OktaResponseError {
  return (e as OktaResponseError).name === 'AuthApiError'
}

/**
 * Safely fetch the value of an environment variable for a given key. Always return a string,
 * even if the environment variable is not found.
 * @param key
 * @returns the value of an environment variable for a given key or empty string if not found
 */
export function getEnvValue(key: string, fallback = ''): string {
  return process.env[`${key}`] ?? fallback
}

/**
 * Check if the provided redirect URL exists in the valid redirects list
 *    If it DOES match, return it
 *    If it DOES NOT match an item in the list, return ''
 * @param url
 * @returns the URL that exists in the valid redirects list OR null
 */
export function getSafeUrl(url: string | null): string {
  if (url) {
    const sanitizedUrl = url
      .toLowerCase()
      .replace(/^https:\/\//, '') // remove scheme
      .replace(/\/$/, '') // remove trailing slash
    return VALID_REDIRECTS.includes(sanitizedUrl)
      ? `https://${sanitizedUrl}`
      : ''
  }
  return ''
}

export function setEmailQueryParam() {
  const email = useRouterQuery(EMAIL_QUERY_PARAM_KEY)
  const username = email ?? localStorage.getItem(LOGIN_USERNAME_KEY) ?? ''
  const [, setUsername] = useLocalStorage<string>(LOGIN_USERNAME_KEY, username)
  useMemo(() => setUsername(username), [username])
}

// Returns true if the user is on the Set Password route
export function isSetPasswordFlow(): boolean {
  const { pathname } = useLocation()

  return pathname.includes('set-password')
}

export const navigateToMissionLaneHome = () => {
  window.location.href = process.env.REACT_APP_MONO_URL as string
}

/**
 * @param error
 * @returns a stringified error message regardless of the type of error
 */
export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}

/**
 * @param error
 * @return an updated error object with the appropriate error message
 * based on the errorCode
 */
export function getAuthError(error: any) {
  let message = error.message
  switch (error.errorCode) {
    case 'E0000068':
      message = 'Invalid code. Please try again.'
      break
  }
  return {
    ...error,
    ...{
      message,
    },
  }
}
