interface Props {
  isActive?: boolean
}

export function ThreeIcon({ isActive }: Props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="13"
        r={isActive ? '11.5' : '12'}
        className={isActive ? 'stroke-blue stroke-[2px]' : 'stroke-grey'}
      />
      <path
        d={
          isActive
            ? 'M14.22 14.052C14.22 14.868 13.488 15.408 12.588 15.408C11.508 15.408 10.944 14.64 10.824 13.764H9.264C9.372 15.36 10.548 16.656 12.54 16.656C14.376 16.656 15.9 15.768 15.9 14.004C15.9 13.128 15.396 12.264 14.496 11.892C15.156 11.532 15.672 10.824 15.672 9.984C15.672 8.34 14.172 7.644 12.72 7.644C10.728 7.644 9.528 8.7 9.336 10.428H10.908C10.992 9.528 11.628 8.892 12.624 8.892C13.344 8.892 14.004 9.3 14.004 10.056C14.004 10.872 13.224 11.328 11.976 11.328H11.688V12.624H12.036C13.272 12.624 14.22 13.032 14.22 14.052Z'
            : 'M14.352 15.064C14.352 16.144 13.536 16.744 12.384 16.744C11.16 16.744 10.524 16 10.404 14.932H9.276C9.396 16.384 10.332 17.656 12.372 17.656C14.028 17.656 15.516 16.804 15.516 15.052C15.516 14.14 15.156 13.276 13.86 12.868C14.772 12.496 15.276 11.74 15.276 10.852C15.276 9.244 13.968 8.644 12.456 8.644C10.632 8.644 9.54 9.712 9.408 11.248H10.536C10.632 10.264 11.268 9.556 12.432 9.556C13.356 9.556 14.124 10.036 14.124 10.972C14.124 11.992 13.32 12.412 11.808 12.412H11.664V13.408H11.844C13.236 13.408 14.352 13.816 14.352 15.064Z'
        }
        className={isActive ? 'fill-blue' : 'fill-grey'}
      />
    </svg>
  )
}
