import { ErrorEmbarrassingIcon } from 'components'
import { useAnalytics } from 'hooks'
import { AnalyticsPage } from 'models'
import { useEffect } from 'react'

export function MaintenancePage() {
  const { page } = useAnalytics()
  useEffect(() => {
    page(`${AnalyticsPage.Login}: ${AnalyticsPage.Maintenance}`)
  }, [])
  return (
    <section
      className={`grid-flow-cols bg-ink-error h-screen grid-cols-1 overflow-scroll lg:grid-rows-2`}
    >
      <div className="mx-8 mt-32 max-w-[900px] md:mx-auto">
        <div className="mx-auto flex justify-center py-6 md:pt-4">
          <ErrorEmbarrassingIcon className="mx-8" />
        </div>
        <h2 className="text-center">We’re under maintenance right now.</h2>
        <p className={`pt-12 text-center text-long-form text-grey`}>
          We’re fixing a few things, but we’ll be back online soon. Please come
          back later and try again.
        </p>
      </div>
    </section>
  )
}
