interface Props {
  isActive?: boolean
}

export function FourIcon({ isActive }: Props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="13"
        r="12"
        className={isActive ? 'stroke-blue stroke-[2px]' : 'stroke-grey'}
      />
      <path
        d="M16.224 15.352V14.32H14.628V8.8H13.512L9.204 14.32V15.352H13.524V17.5H14.628V15.352H16.224ZM10.416 14.32L13.524 10.3V14.32H10.416Z"
        className={isActive ? 'fill-blue' : 'fill-grey'}
      />
    </svg>
  )
}
