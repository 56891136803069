interface Props {
  className?: string
}

export function AnchorIcon({ className = '' }: Props) {
  return (
    <svg
      width="487"
      height="264"
      viewBox="0 0 487 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M89.1296 6.91894C89.5043 4.87298 91.2621 3.43219 92.9046 2.2219C94.0573 1.35742 95.3252 0.464105 96.7083 0.521738C97.5728 0.550554 98.3509 0.982777 99.2153 0.896328C99.8493 0.838696 100.426 0.521724 101.002 0.291193C102.328 -0.169867 103.797 -0.0834112 105.036 0.550547C105.93 1.01161 106.679 1.76084 107.659 2.10663C108.437 2.3948 109.301 2.36597 110.137 2.4236C111.232 2.51005 112.298 2.71175 113.307 3.05755C113.998 3.28808 114.719 3.63389 115.064 4.29666C115.756 5.65103 114.574 7.43763 115.007 8.93608C115.554 10.7515 118.234 11.2126 118.378 13.1432C118.522 15.1316 115.785 16.3995 115.756 18.3878C115.756 18.7336 115.842 19.0506 115.871 19.3964C115.986 20.6643 115.266 22.0187 114.171 22.6238C113.278 23.1137 112.039 23.2866 111.693 24.2664C111.549 24.6698 111.606 25.102 111.405 25.4766C111.174 25.9089 110.684 26.0818 110.223 26.0818C108.754 26.1106 107.63 24.3528 108.148 22.8832C108.35 22.3357 108.725 21.8458 108.869 21.2983C109.013 20.7508 108.782 20.0015 108.264 19.9439C108.12 19.9439 107.975 19.9727 107.86 19.9151C107.514 19.7998 107.572 19.2523 107.687 18.8777C108.004 17.8691 108.408 16.8606 108.35 15.8232C108.235 14.2095 106.967 12.9703 105.555 12.4228C104.143 11.8753 102.587 11.8754 101.06 11.7601C99.5323 11.6736 97.9763 11.4431 96.7372 10.5498C95.6998 9.80056 94.9794 8.64792 93.8555 8.04278C92.8758 7.52409 91.6655 7.49526 90.6281 7.95632C90.3976 8.04276 90.1959 8.18685 90.0518 8.38856C89.9077 8.61909 89.8789 8.90727 89.8789 9.16662C89.8213 10.2905 89.9365 11.6736 89.6195 12.7398C89.072 11.1838 88.8127 8.50383 89.1296 6.91894Z"
        fill="#072D26"
      />
      <path
        d="M24.6285 33.8502L25.483 34.4611L25.6447 35.499L23.1745 35.8839C25.6447 35.499 25.6448 35.4994 25.6449 35.4999L25.645 35.501L25.6455 35.5039L25.6468 35.5122L25.6507 35.5382C25.6539 35.5598 25.6584 35.5897 25.6638 35.6273C25.6746 35.7025 25.6894 35.8086 25.7067 35.9407C25.741 36.2042 25.7854 36.5741 25.8267 37.0093C25.9077 37.8625 25.9841 39.0382 25.9322 40.1755C25.9063 40.7442 25.846 41.3614 25.7166 41.9537C25.5943 42.5133 25.3713 43.24 24.9062 43.8873C24.3885 44.6079 23.5159 45.2812 22.2979 45.3454C21.2215 45.4023 20.3113 44.9526 19.6593 44.5062C18.4343 43.6677 16.8903 43.2912 15.1574 43.2261C13.4237 43.161 11.6809 43.4126 10.176 43.6769C9.62141 43.7744 8.94177 43.8264 8.25435 43.6573C7.49432 43.4704 6.65263 42.9807 6.18638 42.0265C5.76827 41.1708 5.85309 40.3407 5.97088 39.8353C6.09433 39.3056 6.31914 38.8392 6.53555 38.472C6.69072 38.2087 6.86904 37.9503 7.06349 37.7017C6.80741 37.5862 6.55508 37.4704 6.31995 37.3583C5.93715 37.1757 5.5411 36.976 5.18307 36.7675C4.86016 36.5794 4.4143 36.2975 4.04012 35.9233L4.03187 35.9151L4.02368 35.9067C3.24685 35.1152 2.90581 34.0426 2.73693 33.2743C2.54639 32.4075 2.47271 31.4384 2.50789 30.482C2.57295 28.7137 3.04307 26.2964 4.59348 24.6925L4.59845 24.6874L4.60344 24.6823C5.60468 23.6583 6.89941 23.5958 7.68081 23.6703C8.50348 23.7487 9.33551 24.0233 10.0749 24.3293C11.5782 24.9515 13.3271 25.9739 15.0435 27.0798C17.6028 28.7287 20.3941 30.767 22.5967 32.3755C23.3549 32.9291 24.0433 33.4318 24.6285 33.8502ZM9.19116 38.7679C9.19109 38.768 9.19404 38.7679 9.2002 38.7675C9.19431 38.7676 9.19123 38.7678 9.19116 38.7679ZM10.8096 41.0728C10.8088 41.0745 10.8085 41.0753 10.8085 41.0753C10.8085 41.0753 10.8089 41.0745 10.8096 41.0728Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="5"
      />
      <path
        d="M50.7378 108.187L51.5924 107.576L51.7541 106.538L49.2839 106.153C51.7541 106.538 51.7542 106.538 51.7542 106.537L51.7544 106.536L51.7549 106.533L51.7561 106.525L51.7601 106.499C51.7633 106.477 51.7678 106.447 51.7732 106.41C51.784 106.335 51.7988 106.228 51.816 106.096C51.8504 105.833 51.8947 105.463 51.9361 105.028C52.0171 104.175 52.0935 102.999 52.0416 101.862C52.0157 101.293 51.9554 100.676 51.826 100.083C51.7037 99.5238 51.4806 98.7972 51.0156 98.1498C50.4979 97.4292 49.6253 96.756 48.4073 96.6917C47.3309 96.6348 46.4207 97.0845 45.7686 97.5309C44.5437 98.3694 42.9997 98.7459 41.2668 98.811C39.533 98.8761 37.7903 98.6245 36.2854 98.3602C35.7308 98.2627 35.0511 98.2107 34.3637 98.3798C33.6037 98.5667 32.762 99.0564 32.2958 100.011C31.8776 100.866 31.9625 101.696 32.0803 102.202C32.2037 102.731 32.4285 103.198 32.6449 103.565C32.8001 103.828 32.9784 104.087 33.1729 104.335C32.9168 104.451 32.6645 104.567 32.4293 104.679C32.0465 104.861 31.6505 105.061 31.2924 105.27C30.9695 105.458 30.5237 105.74 30.1495 106.114L30.1412 106.122L30.1331 106.13C29.3562 106.922 29.0152 107.995 28.8463 108.763C28.6558 109.63 28.5821 110.599 28.6173 111.555C28.6823 113.323 29.1524 115.741 30.7029 117.345L30.7078 117.35L30.7128 117.355C31.7141 118.379 33.0088 118.441 33.7902 118.367C34.6129 118.288 35.4449 118.014 36.1843 117.708C37.6876 117.086 39.4365 116.063 41.1529 114.957C43.7122 113.308 46.5035 111.27 48.7061 109.662C49.4642 109.108 50.1526 108.605 50.7378 108.187ZM35.3005 103.269C35.3005 103.269 35.3034 103.269 35.3096 103.27C35.3037 103.27 35.3006 103.269 35.3005 103.269ZM36.9189 100.964C36.9182 100.963 36.9178 100.962 36.9178 100.962C36.9179 100.962 36.9182 100.963 36.9189 100.964Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="5"
      />
      <path
        d="M27.5267 216.339C26.673 215.735 26.6938 216.797 25.7567 218.109C23.6328 221.087 11.576 216.36 9.66029 215.943C8.66077 215.714 5.2041 219.962 9.86853 221.816C13.8874 223.419 26.7771 228.562 31.2124 230.27C32.1287 230.624 33.1907 230.332 33.7737 229.52C35.8352 226.647 37.6052 221.607 36.4183 221.024C34.7941 219.671 31.1292 218.88 27.5267 216.339Z"
        fill="#072D26"
      />
      <path
        d="M198.448 192.147C197.948 193.084 198.989 192.918 200.384 193.709C203.591 195.5 200.238 207.994 200.03 209.951C199.926 210.972 204.528 213.928 205.84 209.077C206.964 204.891 210.65 191.502 211.858 186.921C212.108 185.963 211.691 184.942 210.837 184.463C207.756 182.735 202.55 181.548 202.092 182.777C200.946 184.547 200.572 188.274 198.448 192.147Z"
        fill="#072D26"
      />
      <path
        d="M95.7042 98.8328C77.5046 122.467 57.5351 155.326 53.5162 162.469C49.4973 169.632 25.9669 213.195 25.3006 215.86C33.4841 221.295 38.336 224.189 38.336 224.189C41.8551 218.296 61.3041 184.167 68.6755 174.13C78.3792 160.928 111.592 129.068 111.592 129.068C124.149 139.896 137.184 159.991 149.179 171.673C166.15 186.207 198.134 194.016 198.134 194.016C203.215 188.019 205.818 181.585 205.006 180.252C196.801 175.879 165.171 166.446 160.402 158.929C155.634 151.433 143.431 108.391 128.064 92.1485C126.523 90.5243 114.903 95.7301 108.531 98.4371C102.159 101.144 95.7042 98.8328 95.7042 98.8328Z"
        fill="#60CDA9"
      />
      <path
        opacity="0.46"
        d="M107.594 125.549C107.531 125.507 107.51 125.486 107.489 125.486C107.51 125.486 107.594 125.549 107.594 125.549Z"
        fill="#072D26"
      />
      <path
        d="M98.9851 31.2671C98.9705 31.2283 98.9561 31.19 98.9418 31.1523L96.3948 33.4328L95.1822 34.5263C95.3249 35.3715 95.4218 36.4884 95.3459 37.8548L95.3457 37.858C95.229 39.9123 94.7341 42.4764 93.4612 45.5022C93.6237 45.787 93.935 46.1764 94.3879 46.625C94.669 46.9035 94.9659 47.1671 95.2454 47.4004L99.953 45.0338C99.8302 43.4977 99.7825 41.6669 99.7365 39.8965C99.7224 39.358 99.7086 38.8251 99.6928 38.3078C99.6449 36.7382 99.5809 35.2448 99.4597 33.9624C99.3333 32.6261 99.1621 31.737 98.9853 31.2675L98.9851 31.2671ZM93.3688 45.3088C93.3683 45.3086 93.3699 45.3148 93.3749 45.3279C93.3717 45.3155 93.3692 45.309 93.3688 45.3088ZM97.9062 28.7702C97.8816 28.7317 97.8829 28.7398 97.9111 28.7779C97.9094 28.7752 97.9078 28.7726 97.9062 28.7702Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="5"
      />
      <path
        d="M102.098 37.4036C98.6001 37.008 97.2674 42.9635 94.602 43.6298C92.9778 44.0254 93.4776 41.3809 92.5613 40.7145C91.9991 40.2981 91.7075 39.9024 90.9579 41.0061C78.214 59.9553 100.287 103.268 100.287 103.268C100.287 103.268 118.466 109.64 127.024 109.869C137.331 110.14 133.792 107.078 133.084 104.725C132.355 102.352 110.469 44.8584 110.469 44.8584C110.469 44.8584 107.866 41.2143 102.098 37.4036Z"
        fill="#072D26"
      />
      <path
        d="M92.5392 39.9654C70.3207 45.7959 58.8054 50.2729 54.0576 49.3983C42.2716 47.1911 21.844 31.5527 20.2822 31.5527C18.7413 31.5527 16.9505 42.1935 18.8662 43.8801C27.3621 51.3765 46.4155 63.5582 54.2034 63.225C72.5904 62.4337 77.7338 59.0604 80.0035 60.5596C85.7299 64.3287 92.1435 93.8145 92.3934 98.4373C92.6433 103.06 87.0001 103.622 94.0801 108.974C98.1614 112.056 105.783 110.411 107.511 108.12C110.468 104.205 100.639 92.7109 97.3077 80.904C94.1425 69.6594 90.2278 63.35 89.853 53.6671C89.5614 46.3165 92.5392 39.9654 92.5392 39.9654Z"
        fill="#007459"
      />
      <path
        d="M99.911 64.3077C100.015 67.2438 100.348 70.1383 100.994 72.8245C102.264 78.0303 107.095 84.8395 110.052 89.7538C110.635 90.7325 111.156 91.6487 111.551 92.44C117.028 103.56 115.3 110.952 120.609 115.096C125.045 118.553 137.039 124.362 144.119 116.324C146.868 113.201 141.933 105.496 135.79 98.4163C130.167 91.9403 130.438 89.8163 128.168 83.7775C125.961 77.9262 124.795 69.6385 122.754 60.0389C121.463 53.9169 115.758 46.3788 112.592 43.9009C105.054 38.0079 102.202 37.4248 102.202 37.4248C102.493 40.5275 99.4946 52.7299 99.911 64.3077Z"
        fill="#007459"
      />
      <path
        d="M103.262 49.2317C99.2848 60.9136 84.4794 81.258 80.877 84.5064C76.9205 88.088 49.1214 98.0624 44.3945 98.4789C44.686 103.143 46.0604 106.371 48.0386 107.891C50.0376 109.411 82.5845 102.081 90.0392 95.7927C95.724 90.9825 114.278 64.2661 117.797 56.3533C118.609 54.5417 118.734 47.3368 111.404 45.546C104.074 43.7344 103.262 49.2317 103.262 49.2317Z"
        fill="#007459"
      />
      <path
        d="M384.883 227.216C397.908 226.136 420.449 211.259 434.6 190.936C437.736 186.455 440.449 181.694 442.559 176.767C442.751 176.288 442.962 175.802 443.154 175.324C445.369 169.752 446.782 163.51 447.539 157.08C449.83 137.557 445.929 116.176 439.156 105.572C435.823 100.349 431.792 97.7298 427.426 99.2033C424.467 100.212 425.205 104.017 427.142 109.901C430.328 119.523 436.729 134.75 435.432 152.538C435.108 156.829 434.35 161.268 432.984 165.812C432.465 167.561 431.86 169.321 431.143 171.08C430.951 171.559 430.747 172.064 430.536 172.551C430.243 173.226 429.941 173.881 429.64 174.537C427.536 179.081 425.063 183.144 422.403 186.788C409.787 204.022 392.263 211.977 382.689 217.158C377.454 220.004 374.593 222.003 376.244 224.262C377.968 226.65 380.983 227.543 384.883 227.216Z"
        fill="#072D26"
      />
      <path
        d="M421.908 116.103C424.79 117.34 425.699 113.625 427.15 109.921C428.102 107.509 429.278 105.098 431.407 104.081C433.593 103.041 436.49 104.317 439.163 105.591C442.303 107.085 445.157 108.534 446.194 106.112C451.754 93.0669 434.276 81.3087 423.293 86.6645C417.02 89.7173 408.883 110.536 421.908 116.103Z"
        fill="#072D26"
      />
      <path
        d="M381.29 223.55C383.434 229.107 396.543 230.568 392.573 233.899C380.836 243.76 364.896 236.588 363.328 230.335C361.241 222.026 370.296 207.515 384.426 208.954C389.28 209.459 379.391 218.614 381.29 223.55Z"
        fill="#072D26"
      />
      <path
        d="M426.546 190.773C428.074 191.358 431.048 191.35 434.608 190.955C444.288 189.85 458.269 185.797 459.523 182.618C459.75 182.058 459.809 181.297 459.744 180.337C459.351 174.964 454.462 164.2 447.554 157.119C446.173 155.715 444.707 154.435 443.2 153.395C439.236 150.66 437.565 151.362 435.448 152.577C433.605 153.638 431.467 155.085 427.202 154.997C420.204 154.844 352.433 128.587 317.276 114.505C316.165 114.07 315.092 113.62 314.054 113.2C309.983 111.559 306.464 110.125 303.64 108.977C303.188 108.797 302.756 108.609 302.332 108.44C301.159 107.963 300.14 107.536 299.303 107.172C299.006 107.042 298.709 106.913 298.439 106.796C297.736 106.491 297.189 106.236 296.818 106.091C295.194 105.321 293.696 104.926 292.432 104.842C288.083 104.541 286.264 107.764 289.785 111.077C290.612 111.871 291.749 112.654 293.214 113.419C293.635 113.634 294.279 113.962 295.085 114.361C295.37 114.517 295.702 114.678 296.034 114.838C296.867 115.248 297.823 115.744 298.934 116.292C301.861 117.757 305.656 119.664 310.122 121.911C311.044 122.376 311.992 122.853 312.995 123.353C347.319 140.606 413.283 173.863 417.337 178.018C420.741 181.489 421.371 184.509 422.433 186.866C423.148 188.509 424.13 189.867 426.546 190.773Z"
        fill="#072D26"
      />
      <path
        d="M302.448 124.531C304.284 125.33 306.228 125.549 308.074 125.291C309.835 125.046 311.513 124.362 312.953 123.302C313.772 122.711 314.491 121.98 315.126 121.148C315.274 120.956 315.402 120.771 315.523 120.567C315.859 120.054 316.14 119.518 316.386 118.951L316.398 118.924C317.032 117.466 317.28 115.936 317.253 114.447C317.183 112.393 316.536 110.41 315.384 108.737C314.283 107.134 312.743 105.839 310.826 105.005C308.8 104.124 306.647 103.943 304.631 104.334C303.984 104.455 303.361 104.633 302.741 104.877C301.462 105.381 300.283 106.137 299.272 107.094C298.864 107.479 298.479 107.921 298.148 108.388C297.649 109.054 297.24 109.775 296.899 110.558C296.57 111.314 296.354 112.071 296.21 112.844C296.087 113.496 296.029 114.146 296.03 114.772C296.031 116.764 296.597 118.712 297.656 120.376C298.748 122.184 300.376 123.646 302.448 124.531Z"
        fill="#072D26"
      />
      <path
        d="M291.402 157.395C290.132 153.263 301.228 136.717 308.082 125.311C308.815 124.104 309.486 122.967 310.095 121.899C310.68 120.885 311.184 119.948 311.633 119.1C311.747 118.876 311.86 118.653 311.954 118.437C312.079 118.186 312.185 117.943 312.298 117.719C312.411 117.496 312.505 117.279 312.587 117.09C313.069 115.983 313.54 114.679 314.035 113.208C314.483 111.845 314.931 110.371 315.388 108.803C319.047 96.1042 323.012 77.4211 327.619 73.5476C335.224 67.1725 332.193 64.7629 327.448 62.7156C321.737 60.2649 317.641 63.4451 320.36 70.7446C321.63 74.1386 310.91 93.401 304.634 104.4C304.092 105.352 303.573 106.251 303.108 107.06C302.831 107.55 302.566 108.012 302.324 108.421C301.231 110.306 300.509 111.598 300.337 111.957C300.142 112.369 299.646 113.952 298.926 116.272C298.549 117.473 298.116 118.874 297.651 120.422C294.016 132.216 287.643 152.229 283.708 154.114C278.277 156.746 278.347 163.343 282.66 165.203C289.956 168.327 293.032 162.728 291.402 157.395Z"
        fill="#072D26"
      />
      <path
        d="M272.837 100.095C275.757 93.305 283.608 90.1678 290.398 93.0882C295.919 95.4567 299.01 101.088 298.423 106.757C298.374 107.314 298.278 107.866 298.136 108.415C297.966 109.175 297.722 109.92 297.386 110.657C297.045 111.44 296.643 112.181 296.179 112.878C295.844 113.391 295.455 113.88 295.05 114.33C291.316 118.487 285.226 120.013 279.824 117.664C273.053 114.736 269.916 106.886 272.837 100.095ZM289.77 111.039C290.682 110.344 291.41 109.408 291.892 108.3C292.385 107.166 292.538 105.964 292.417 104.804C292.136 102.273 290.482 99.9484 287.924 98.8522C284.178 97.2394 279.873 98.8679 278.338 102.471C276.784 106.083 278.568 110.326 282.306 111.92C284.891 113.028 287.743 112.598 289.77 111.039Z"
        fill="#072D26"
      />
      <path
        d="M273.782 93.2002C267.739 92.921 259.818 89.199 263.383 88.15C266.948 87.1009 272.593 91.3584 273.935 92.337C275.277 93.3155 280.003 77.4387 281.31 82.329C282.618 87.2194 278.702 92.6076 275.277 93.3155C271.855 94.0027 276.902 101.757 280.009 101.686M272.446 95.1536C247.788 99.4715 131.529 95.5856 131.529 95.5856M131.529 95.5856C131.529 95.5856 103.316 104.241 90.9654 98.8389M131.529 95.5856C138.438 91.0144 140.588 87.4924 134.471 88.713C128.458 89.9445 131.529 95.5856 131.529 95.5856ZM131.268 96.4794C129.121 93.1968 141.085 99.291 141.007 102.423C140.918 105.262 135.54 103.042 131.268 96.4794Z"
        stroke="#60CDA9"
        strokeWidth="13.6446"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.805 23.9169C110.346 22.4142 109.695 20.5005 108.163 19.7326C108.1 19.7022 108.048 19.6521 108.011 19.5883C107.975 19.5244 107.956 19.4495 107.957 19.3724C108.015 11.7914 103.805 5.87431 98.8374 5.07231C92.2432 4.01117 86.4234 12.3058 85.513 19.4901C84.5697 26.9393 88.5034 36.4068 95.1538 36.6661C99.7462 36.845 104.603 32.6145 106.809 26.2172C106.835 26.1407 106.881 26.074 106.94 26.0258C107 25.9776 107.071 25.9503 107.143 25.9473C107.702 25.9169 108.249 25.7102 108.72 25.3509C109.191 24.9917 109.568 24.4946 109.805 23.9169Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
