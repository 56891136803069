import { useForm } from 'react-hook-form'
import { AuthError, HookFormRadioGroup, SubmitActionButtons } from 'components'
import { Formik, Form, Field } from 'formik'
import { useMemo, useState } from 'react'
import { navigateToMissionLaneHome } from 'utilities'
import { StepProps } from '../../shared/step-props.interface'
import { AnalyticsEvent } from 'models'
import { useLoginMfaContext } from 'contexts/LoginMfaContext'
import { useAnalytics } from 'hooks'

export function SelectFactorTypeForm({ onSubmit, options }: StepProps) {
  const { isSubmitting, authError } = useLoginMfaContext()
  const { trackClickEvent } = useAnalytics()
  const { register } = useForm()
  const [factorType, setFactorType] = useState<string>('')
  const canSubmit = useMemo(
    () => !!factorType && !isSubmitting,
    [factorType, isSubmitting]
  )

  const handleFactorTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFactorType(event.currentTarget.value)
    const factorSelected =
      factorType === 'sms' ? AnalyticsEvent.Text : AnalyticsEvent.Call
    trackClickEvent({
      event: `${AnalyticsEvent.MfaSelection}: ${factorSelected}`,
    })
  }

  return (
    <Formik
      onSubmit={async () => await onSubmit(factorType)}
      initialValues={{ factorType }}
    >
      <Form className="grid grid-cols-2 md:grid-cols-4 lg:gap-6">
        <Field
          as={HookFormRadioGroup}
          currentValue={factorType}
          disabled={isSubmitting}
          register={register}
          fieldName="factorType"
          options={options}
          onChange={handleFactorTypeChange}
          className="col-span-4 grid grid-cols-1 gap-2 md:row-start-2 md:grid-cols-2 lg:gap-5"
        />
        {authError && (
          <AuthError
            className="col-span-4 row-start-3"
            errors={authError.message || 'Invalid options. Please try again.'}
          />
        )}
        <SubmitActionButtons
          disableSubmit={!canSubmit}
          submitButtonText={isSubmitting ? 'Sending...' : 'Send Code'}
          onCancel={navigateToMissionLaneHome}
          className="col-span-4 row-start-4"
        />
      </Form>
    </Formik>
  )
}
