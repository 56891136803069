import { useAnalytics } from 'hooks'

interface Props {
  children: React.ReactNode
  to: string
  className?: string
  title?: string
  newTab?: boolean
  analyticsEventName?: string
}

export function ExternalLink({
  to,
  children,
  className = '',
  title = '',
  newTab = false,
  analyticsEventName,
}: Props) {
  const { trackClickEvent } = useAnalytics()
  return (
    <a
      href={to}
      className={`${className}`}
      title={title}
      target={newTab ? '_blank' : '_self'}
      rel="noreferrer"
      onClick={() => {
        if (analyticsEventName)
          trackClickEvent({
            event: analyticsEventName,
          })
      }}
    >
      {children}
    </a>
  )
}
