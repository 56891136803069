import { Loading } from 'components'
import { useQueryParamContext } from 'contexts'
import { useAnalytics, useAuth } from 'hooks'
import Cookies from 'js-cookie'
import { AnalyticsPage } from 'models'
import { useEffect, useState } from 'react'
import { COOKIE_EMAIL_KEY, ML_HOME, notifyBugsnag } from 'utilities'
import { COOKIE_ATTRIBUTES } from 'utilities/CookieManager'

export function Logout() {
  const qp = useQueryParamContext()
  const [isLoggingOut, setIsLoggingOut] = useState(true)
  const { auth } = useAuth()
  const { page, unidentify } = useAnalytics()

  useEffect(() => {
    page(`${AnalyticsPage.Login}: ${AnalyticsPage.Logout}`, {
      redirectUrl: qp.redirect,
    })
    /* Upon logging out, remove the user's email cookie */
    Cookies.remove(COOKIE_EMAIL_KEY, COOKIE_ATTRIBUTES)
    const revokeAccessToken = async () => {
      await auth.revokeAccessToken()
    }
    try {
      auth.tokenManager.clear()
      revokeAccessToken().catch((error: any) => {
        notifyBugsnag({
          error,
          name: error.name,
          prefix: `${JSON.stringify(error.errorCode)}-LogoutRevokeAccessToken`,
        })
        throw new Error(error)
      })
      auth
        .closeSession()
        .then(() => {
          setIsLoggingOut(false)
        })
        .catch((error) => {
          notifyBugsnag({
            error,
            name: error.name,
            prefix: `${JSON.stringify(error.errorCode)}-LogoutCloseSession`,
          })
          throw new Error(error)
        })
      unidentify()
    } catch (e: any) {
      setIsLoggingOut(false)
    }
  }, [])

  return (
    <>
      {isLoggingOut && <Loading message="Logging you out..." />}
      {!isLoggingOut &&
        window.location.replace(qp.redirect ? qp.redirect : ML_HOME)}
    </>
  )
}
