import { TextField } from '../components/shared/Form/Formik'
import { Alert, SubmitActionButtons, LoginLayout } from 'components'
import { Form, Formik, FormikHelpers } from 'formik'
import { useAnalytics, useEmailCookie, useLocalStorage } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { areCookiesEnabled } from 'scripts/areCookiesEnabled'
import {
  AUTHORIZE_ROUTE,
  ERROR_ROUTE,
  LOGIN_USERNAME_KEY,
} from 'utilities/constants'
import { useQueryParamContext } from 'contexts'
import {
  EMAIL_VALIDATION_SCHEMA,
  navigateToMissionLaneHome,
  notifyBugsnag,
} from 'utilities'
import { AnalyticsError, AnalyticsEvent, AnalyticsPage } from 'models'
import { LegalDisclaimer } from 'components/shared/LegalDisclaimer'
// Not-MVP, but keep for post-MVP
// import { LoginLink } from './LoginLink';

interface FormValues {
  username: string
}

export function LoginUsername() {
  const { search } = useLocation()
  const [username, setUsername] = useLocalStorage<string>(
    LOGIN_USERNAME_KEY,
    ''
  )
  const { redirect, redirectActual } = useQueryParamContext()
  const hasValidRedirectUrl = !!redirect
  const [canSubmit, setCanSubmit] = useState<boolean>(
    !!username && hasValidRedirectUrl
  )
  const initialValues: FormValues = {
    username,
  }
  const navigate = useNavigate()
  const cookiesAreEnabled = useRef(areCookiesEnabled()).current
  const { identify, page, trackClickEvent, trackEvent } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.Login}: ${AnalyticsPage.EnterEmail}`

  useEffect(() => {
    page(analyticsPageName, { redirect })
    if (!hasValidRedirectUrl) {
      trackEvent(AnalyticsError.MissingRedirect, {
        event: AnalyticsEvent.RedirectToError,
        redirect,
      })
      notifyBugsnag({
        error: new Error(
          redirectActual ? `Redirect=${redirectActual}` : 'Redirect is missing'
        ),
        name: 'Invalid Redirect',
      })
      navigate(ERROR_ROUTE)
    } else {
      /**
       *  if the username is already set in local storage, automatically
       *    redirect the user to the password entry page
       */
      const { value } = useEmailCookie()
      const storedEmail = value ?? username
      if (storedEmail) {
        identify('', storedEmail)
        setUsername(storedEmail)
        navigate(AUTHORIZE_ROUTE)
      }
    }
  }, [])

  const handleProceed = async (
    { username }: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    trackClickEvent({
      event: `${AnalyticsEvent.Login}: ${AnalyticsEvent.SubmitEmail}`,
      redirect,
    })
    setStatus('')
    setCanSubmit(hasValidRedirectUrl)
    try {
      setUsername(username)
      navigate(`${AUTHORIZE_ROUTE}${search}`, { replace: true })
    } catch (error: any) {
      setStatus('error')
      setCanSubmit(false)
      setSubmitting(false)
      notifyBugsnag({ error: error as Error, name: 'SetUsername' })
    }
  }

  const autoFocusInput: string = username

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanSubmit(!!event.currentTarget.value && hasValidRedirectUrl)
  }

  return (
    <LoginLayout
      title="Welcome to Mission Lane!"
      subtitle="Let's get you logged in."
      body="Please enter your email address to get started."
    >
      <section>
        {/* Not-MVP, but keep for post-MVP
        <div>
          New Here?&nbsp;
          <LoginLink external to="https://apply.missionlane.com/lookup">
            Create an account
          </LoginLink>
        </div> */}
        <Formik
          validationSchema={EMAIL_VALIDATION_SCHEMA}
          onSubmit={handleProceed}
          initialValues={initialValues}
        >
          {({ setFieldValue }) => (
            <Form className="grid">
              <TextField
                inputProps={{
                  autoFocus: autoFocusInput === LOGIN_USERNAME_KEY,
                  onChange: handleUsernameChange,
                }}
                id="username"
                name="username"
                label="Email"
                placeholder="Email address"
              />
              {/* Not-MVP, but keep for post-MVP
                <div className='float-right'>
                  <LoginLink
                    onClick={() => {
                      setUsername('')
                      setFieldValue(LOGIN_USERNAME_KEY, '')
                    }}
                  >
                    Need Help?
                  </LoginLink>
                </div> */}
              {!cookiesAreEnabled && (
                <Alert
                  type="warn"
                  message="Please turn on cookies in your browser settings to log in."
                />
              )}
              <SubmitActionButtons
                disableSubmit={!canSubmit}
                submitButtonText="Enter Password"
                onCancel={navigateToMissionLaneHome}
              />
            </Form>
          )}
        </Formik>
        <LegalDisclaimer analyticsPage={analyticsPageName} />
      </section>
    </LoginLayout>
  )
}
