import { useChangePasswordContext } from 'contexts'
import { ChangePasswordLayout } from '../components/mfa/shared/container'
import { useEffect } from 'react'
import { useAnalytics } from 'hooks'
import { Outlet } from 'react-router-dom'

/**
 * This component manages displaying the correct form/step in the Forgot Password flow
 * based on the activeStep from the Forgot Password context. It also handles the submit
 * logic for each form
 */
export function ForgotPassword() {
  const { activeStep, analyticsPageName } = useChangePasswordContext()

  const { page } = useAnalytics()
  useEffect(() => {
    page(analyticsPageName, { activeStep })
  }, [analyticsPageName, activeStep])

  return (
    <ChangePasswordLayout>
      <Outlet />
    </ChangePasswordLayout>
  )
}
