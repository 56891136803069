import TextField from '../../shared/Form/Formik/fields/TextField'
import {
  Alert,
  AuthError,
  LoginActionButtons,
  LoginLayout,
  OktaErrorMessage,
  UserIcon,
} from 'components'
import { Form, Formik, FormikHelpers } from 'formik'
import { useAnalytics, useAuth, useEmailCookie, useLocalStorage } from 'hooks'
import {
  AnalyticsError,
  AnalyticsEvent,
  AnalyticsPage,
  LoginInputOptions,
  OktaResponseError,
} from 'models'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { areCookiesEnabled } from 'scripts/areCookiesEnabled'
import { useQueryParamContext } from 'contexts'
import {
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  PASSWORD_INPUT_TYPE,
  LOGIN_USERNAME_KEY,
  REQUIRED_VALIDATION_SCHEMA,
  COOKIE_ID_TOKEN_KEY,
  ERROR_ROUTE,
  isOktaError,
  notifyBugsnag,
} from 'utilities'
import { LoginLink } from './LoginLink'
import useBreakpoint from 'hooks/useBreakpoint'
import { LegalDisclaimer } from 'components/shared/LegalDisclaimer'

interface FormValues {
  username: string
  password: string
}

export function LoginPassword() {
  const [username, setUsername] = useLocalStorage<string>(
    LOGIN_USERNAME_KEY,
    ''
  )
  const [password, setPassword] = useLocalStorage<string>(
    PASSWORD_INPUT_TYPE,
    ''
  )
  const [canSubmit, setCanSubmit] = useState<boolean>(!!password)
  const initialValues: FormValues = {
    username,
    password: '',
  }
  const { redirect } = useQueryParamContext()
  const hasValidRedirectUrl = !!redirect
  const navigate = useNavigate()
  const { search } = useLocation()
  const { auth } = useAuth()
  const [authError, setAuthError] = useState<OktaResponseError>()
  const cookiesAreEnabled = useRef(areCookiesEnabled()).current
  const analyticsPageName = `${AnalyticsPage.Login}: ${AnalyticsPage.EnterPassword}`

  const handleSubmit = async (
    { username, password }: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    trackClickEvent({
      event: `${AnalyticsEvent.Login}: ${AnalyticsEvent.SubmitPassword}`,
    })
    isEmailCookiePresent ?? setUsername(value ?? '')
    setPassword(password)
    setAuthError(undefined)
    setStatus('')
    setCanSubmit(true)
    try {
      await auth
        // authenticate user
        .signInWithCredentials({
          username,
          password,
        })
        .then(async (transaction) => {
          if (transaction.status === 'SUCCESS') {
            setStatus('success')
            setPassword('')
            // user is authenticated, get and store id_token cookie
            return await auth.token.getWithRedirect({
              sessionToken: transaction.sessionToken,
              responseType: COOKIE_ID_TOKEN_KEY,
            })
          } else {
            // authentication failed
            throw new Error('Error')
          }
        })
    } catch (error: any) {
      setAuthError(error)
      setPassword('')
      setStatus('error')
      setCanSubmit(false)
      setSubmitting(false)
      /**
       * Okta Error reporting to bugsnag already handled in OktaErrorMessage.tsx
       */
      if (!isOktaError(error)) {
        notifyBugsnag({ error, name: error.name, prefix: error.errorCode })
      }
    }
  }

  const autoFocusInput: LoginInputOptions = username
    ? PASSWORD_INPUT_TYPE
    : LOGIN_USERNAME_KEY

  const navigateToMissionLaneHome = () => {
    window.location.href = process.env.REACT_APP_MONO_URL as string
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanSubmit(!!event.currentTarget.value)
  }

  const { isEmailCookiePresent, value } = useEmailCookie()
  const { page, trackClickEvent, trackEvent, unidentify, identify } =
    useAnalytics()

  useEffect(() => {
    identify('', username ?? value)
    page(analyticsPageName)
  }, [])

  const { isDesktop } = useBreakpoint()
  const title = isDesktop
    ? 'Welcome to Mission Lane!'
    : "Let's get you logged in."
  const subtitle = isDesktop
    ? "Let's get you logged in."
    : 'Please enter your password to log in.'
  const bodyText = isDesktop ? 'Next, please enter your password.' : ''

  useEffect(() => {
    if (!hasValidRedirectUrl) {
      trackEvent(AnalyticsError.MissingRedirect, {
        event: AnalyticsEvent.RedirectToError,
        redirect,
      })
      navigate(ERROR_ROUTE)
    }
  }, [auth])

  return (
    <LoginLayout
      title={title}
      subtitle={subtitle}
      body={bodyText}
      isPasswordPage={true}
    >
      <Formik
        validationSchema={REQUIRED_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting, status }) => (
          <>
            <div className="banner mb-2">
              <UserIcon className="pr-4" />
              {isEmailCookiePresent ? value : username}
            </div>
            <div
              className={`${
                isEmailCookiePresent ? 'hidden' : ''
              } pb-medium text-right`}
            >
              <span className="text-grey">Not you?</span>&nbsp;
              {username && (
                <LoginLink
                  onClick={() => {
                    setUsername('')
                    unidentify()
                    navigate(`${HOME_ROUTE}${search}`, { replace: true })
                  }}
                  analyticsEventName={`${AnalyticsEvent.Login}: ${AnalyticsEvent.ChangeAccounts}`}
                >
                  Change accounts
                </LoginLink>
              )}
            </div>
            <Form>
              <TextField
                inputProps={{
                  className: 'float-left',
                  autoFocus: autoFocusInput === PASSWORD_INPUT_TYPE,
                  onChange: handlePasswordChange,
                }}
                id="password"
                name="password"
                label="Password"
                type={PASSWORD_INPUT_TYPE}
                placeholder="Password"
              />
              <LoginLink
                className="float-right pt-1"
                onClick={() => navigate(`${FORGOT_PASSWORD_ROUTE}/1`)}
                analyticsEventName={`${AnalyticsEvent.Login}: ${AnalyticsEvent.ForgotPassword}`}
              >
                Forgot password?
              </LoginLink>
              {/* <div className="mb3 mb4-ns">
                <LoginLink external to="https://apply.missionlane.com/lookup">
                  New Member? Set up your account.
                </LoginLink>
              </div> */}
              {authError &&
                (authError.errorCode ? (
                  <OktaErrorMessage
                    error={authError}
                    className="mt-3 sm:mt-8"
                  />
                ) : (
                  <AuthError errors="Sorry, we're having trouble signing you in right now due to technical issues on our end. Please try again in a bit." />
                ))}
              {!cookiesAreEnabled && (
                <Alert
                  type="warn"
                  message="Please turn on cookies in your browser settings to log in."
                />
              )}
              <LoginActionButtons
                isSuccess={status === 'success' && !isSubmitting}
                disableSubmit={!canSubmit}
                onCancel={navigateToMissionLaneHome}
                isSubmitting={isSubmitting}
                className="mt-8"
              />
            </Form>
            <LegalDisclaimer analyticsPage={analyticsPageName} />
          </>
        )}
      </Formik>
    </LoginLayout>
  )
}
