import { AuthTransaction } from '@okta/okta-auth-js'
import { useLocalStorage, useAuth, useAnalytics } from 'hooks'
import {
  forgotPassword,
  resendCode,
} from 'components/mfa/forgot-password/auth-functions'
import { createContext, useContext, useMemo, useState } from 'react'
import { LOGIN_USERNAME_KEY } from 'utilities'
import {
  AnalyticsEvent,
  AnalyticsPage,
  MfaStep,
  OtpFactorType,
  LoginMfaConfig,
} from 'models'

export const LoginMfaContext = createContext<LoginMfaConfig>(getInitialValue())
LoginMfaContext.displayName = 'LoginMfa'

export const useLoginMfaContext = () => useContext(LoginMfaContext)

interface Props {
  children: React.ReactNode
}

export const LoginMfaContextProvider = ({ children }: Props) => {
  const { auth } = useAuth()
  const [isOnSubStep, setIsOnSubStep] = useState<boolean>(false)

  const { trackClickEvent } = useAnalytics()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [username] = useLocalStorage<string>(LOGIN_USERNAME_KEY, '')
  const [factorType, setFactorType] = useState<OtpFactorType>('sms')
  const initial = getInitialValue()
  const [activeStep, setActiveStep] = useState<number>(initial.activeStep)
  const [steps, setCompletedSteps] = useState<MfaStep[]>(initial.steps)
  const [title, setTitle] = useState<string>(initial.title)
  const [subtitle, setSubtitle] = useState<string>(initial.subtitle)
  const [mobileTitle, setMobileTitle] = useState<string>(initial.mobileTitle)
  const [mobileSubtitle, setMobileSubtitle] = useState<string>(
    initial.mobileSubtitle
  )
  const [verifyCode, setVerifyCode] = useState<string>('')
  const [authError, setAuthError] = useState<any>()
  const [authTransaction, setAuthTransaction] = useState<AuthTransaction>()
  /**
   * Set the name of the Forgot Password current page
   * based on context metadata for use with analytics logging
   * since the route doesn't change within the forgot password
   * flow
   */
  const analyticsPageName = 'LoginMfa'

  /**
   * Do not prepend forgot password event names with `${AnalyticsPage.LoginMfa}: `
   */
  const analyticsEventName = useMemo(
    () => analyticsPageName.replace(`${AnalyticsPage.LoginMfa}: `, ''),
    [analyticsPageName]
  )

  /**
   * Set the new active step index based on the incoming value
   * which tells this function to increment or decrement the index
   *
   * @param value is 1 (increment) OR -1 (decrement)
   */
  const updateActiveStep = (value: number) => {
    const currentActiveStepIndex = activeStep
    const newActiveStepIndex = currentActiveStepIndex + value
    const newStep = steps[`${newActiveStepIndex}`]
    setCompletedSteps(getUpdatedSteps(newActiveStepIndex))
    setActiveStep(newActiveStepIndex)
    setTitle(newStep.title)
    setSubtitle(newStep.subtitle)
    setMobileTitle(newStep.mobileTitle)
    setMobileSubtitle(newStep.mobileSubtitle)
  }

  /**
   * Update the value that determines whether to display a sub step or not
   *
   * @param value the boolean value to update the state with
   */
  const updateIsOnSubStep = (value: boolean) => {
    setIsOnSubStep(value)
    if (value) {
      setTitle(steps[`${activeStep}`].subStep?.title ?? '')
      setSubtitle(steps[`${activeStep}`].subStep?.subtitle ?? '')
      setMobileTitle(steps[`${activeStep}`].subStep?.mobileTitle ?? '')
      setMobileSubtitle(steps[`${activeStep}`].subStep?.mobileSubtitle ?? '')
    } else {
      setTitle(steps[`${activeStep}`].title)
      setSubtitle(steps[`${activeStep}`].subtitle)
      setMobileTitle(steps[`${activeStep}`].mobileTitle ?? '')
      setMobileSubtitle(steps[`${activeStep}`].mobileSubtitle ?? '')
    }
  }

  /**
   * Update the steps array to set the previous step as completed
   * and the current step as not completed
   *
   * Do not attempt to set a value for a step at a negative index as
   * that will throw an error
   */
  function getUpdatedSteps(activeIndex: number): MfaStep[] {
    if (activeIndex > 0) steps[`${activeIndex - 1}`].isCompleted = true
    steps[`${activeIndex}`].isCompleted = false
    return [...steps]
  }

  function goBack(): void {
    setIsSubmitting(false)
    if (isOnSubStep) {
      updateIsOnSubStep(false)
    } else {
      updateActiveStep(-1)
    }
  }

  /**
   * Call the forgotPassword function for the given factor type to trigger
   * an action that will either text or call the user to provide a
   * verification code
   *
   * @param event submit event
   * @param factor either 'sms' or 'call'
   */
  async function forgotPasswordWithFactor(event: any, factor: OtpFactorType) {
    event.preventDefault()
    setFactorType(factor)
    try {
      const transaction = await forgotPassword(auth, username, factor)
      setAuthTransaction(transaction)
    } catch (error) {
      // notifyBugsnag({ error: error as Error, name: 'Auth-LoginMfa' })
      setAuthError(error)
    }
  }

  const onResendCode = async (event: any) => {
    event.preventDefault()
    setAuthError(undefined)
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.ResendCode}`,
    })
    await resendCode(authTransaction)
  }

  const onCall = async (event: any) => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.CallInstead}`,
    })
    await forgotPasswordWithFactor(event, 'call')
  }

  const onText = async (event: any) => {
    trackClickEvent({
      event: `${analyticsPageName} : ${AnalyticsEvent.TextInstead}`,
    })
    await forgotPasswordWithFactor(event, 'sms')
  }

  return (
    <LoginMfaContext.Provider
      value={{
        activeStep,
        updateActiveStep,
        isOnSubStep,
        updateIsOnSubStep,
        steps,
        subtitle,
        title,
        mobileSubtitle,
        mobileTitle,
        goBack,
        isSubmitting,
        setIsSubmitting,
        username,
        factorType,
        setFactorType,
        verifyCode,
        setVerifyCode,
        onResendCode,
        onCall,
        onText,
        analyticsPageName,
        analyticsEventName,
        authError,
        setAuthError,
      }}
    >
      {children}
    </LoginMfaContext.Provider>
  )
}

function getInitialValue(): LoginMfaConfig {
  return {
    goBack: () => null,
    setAuthError: () => null,
    setFactorType: () => null,
    setIsSubmitting: () => null,
    setVerifyCode: () => null,
    updateActiveStep: () => null,
    updateIsOnSubStep: () => null,
    onCall: async () => await Promise.resolve(null),
    onResendCode: async () => await Promise.resolve(null),
    onText: async () => await Promise.resolve(null),
    factorType: 'sms',
    activeStep: 0,
    title: "Before logging you in, we need to make sure it's you.",
    mobileTitle: 'Choose method of verification',
    subtitle:
      "We can either call or text the phone number associated with your account to provide your verification code. Select your preference and we'll call or text you right away.",
    mobileSubtitle: '',
    username: '',
    verifyCode: '',
    analyticsPageName: AnalyticsPage.LoginMfa,
    analyticsEventName: '',
    steps: [
      {
        id: 0,
        isCompleted: false,
        name: 'Verification code',
        mobileSubtitle: '',
        mobileTitle: 'Choose method of verification',
        subtitle: 'Please enter the verification code we ',
        title: 'Almost There!',
        hasSubStep: true,
        subStep: {
          name: 'Verification code Sub Step',
          subtitle: 'Please enter the verification code we',
          title: 'Almost there!',
          mobileTitle: 'We’ve sent you a verification code.',
          mobileSubtitle: '',
        },
      },
    ],
  }
}
