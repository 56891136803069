import { getEnvValue } from './functions'

export const LOGIN_USERNAME_KEY = 'username'
export const PASSWORD_INPUT_TYPE = 'password'
export const REDIRECT_QUERY_PARAM_KEY = 'redirect'
export const EMAIL_QUERY_PARAM_KEY = 'email'
export const LOGIN_INTENTION_QUERY_PARAM_KEY = 'loginIntention'

/**
 * Routes
 */
export const HOME_ROUTE = '/'
export const AUTHORIZE_ROUTE = '/authorize'
export const CALLBACK_ROUTE = '/callback'
export const LOGOUT_ROUTE = '/logout'
export const ERROR_ROUTE = '/error'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const SET_PASSWORD_ROUTE = '/set-password'
export const LOGIN_MFA_ROUTE = '/login-mfa'
export const MAINTENANCE_ROUTE = '/maintenance'

/**
 * Mission Lane URLs
 */
export const ML_HOME = 'https://www.missionlane.com'
export const ML_HELP_LINK =
  'https://www.missionlane.com/contact-us?topic=help%2Botp'
export const ML_CONTACT_US_LINK = 'https://www.missionlane.com/contact-us'
export const ML_LEGAL_LINK = 'https://www.missionlane.com/legal'
export const ML_PRIVACY_NOTICE_LINK =
  'https://www.missionlane.com/legal/privacy-and-security'

/**
 * Valid Redirect URLs
 */
const CBA_REDIRECTS = getEnvValue('REACT_APP_PRODUCT_CBA_REDIRECTS').split(',')
const REGISTRATION_REDIRECTS = getEnvValue(
  'REACT_APP_PRODUCT_REGISTRATION_REDIRECTS'
).split(',')
const ML_REDIRECTS = getEnvValue('REACT_APP_PRODUCT_ML_REDIRECTS', '').split(
  ','
)

export const VALID_REDIRECTS = new Array<string>()
  .concat(CBA_REDIRECTS)
  .concat(REGISTRATION_REDIRECTS)
  .concat(ML_REDIRECTS)

/**
 * ThreatMetrix (TMX)
 */
export const TMX_BASE_SESSION_ID_KEY = 'tmx-base-session-id'
export const TMX_SESSION_ID_KEY = 'tmx-session-id'

/**
 * Launch Darkly
 */
export const LD_PRE_AUTH_USER_KEY = 'pre_auth_user_key'

/**
 * Cookies
 */
export const COOKIE_ID_TOKEN_KEY = 'id_token'
export const COOKIE_EMAIL_KEY = 'email'
