import { CALLBACK_ROUTE } from 'utilities'

const CLIENT_ID: string = process.env.REACT_APP_OKTA_CLIENT_ID ?? '{clientId}'
const ISSUER: string =
  process.env.REACT_APP_OKTA_ISSUER_URL ??
  'https://{yourOktaDomain}.com/oauth2/aus1focdfvUUvVmmb5d7'
const OKTA_DISABLEHTTPSCHECK: boolean =
  process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK === 'true' || false
const REDIRECT_URI: string = `${window.location.origin}${CALLBACK_ROUTE}`
// const REDIRECT_URI = 'https://local.missionlane-staging.com/callback'
const USE_INTERACTION_CODE: boolean =
  process.env.REACT_APP_USE_INTERACTION_CODE === 'true' || false

export const OPEN_ID_CONFIG = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
}
