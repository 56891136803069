import { LoginLink } from 'components'

interface Props {
  text: string
  external?: boolean
  link: string
  className?: string
  linkText: string
  analyticsEventName?: string
}

export function TextAndLink({
  text,
  external = false,
  link,
  className = '',
  linkText,
  analyticsEventName,
}: Props) {
  return (
    <div className={className}>
      {text}
      <LoginLink
        external={external}
        to={link}
        analyticsEventName={analyticsEventName}
      >
        {linkText}
      </LoginLink>
    </div>
  )
}
