import { ExternalLink } from 'components'
import { useAnalytics } from 'hooks'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface LoginLinkOnClickProps extends LoginLinkBaseProps {
  to?: undefined
  onClick: (e: React.MouseEvent) => void
  external?: undefined
}

interface LoginLinkToProps extends LoginLinkBaseProps {
  to: string
  onClick?: undefined
  external?: boolean
}

interface LoginLinkBaseProps {
  onClick?: (e: React.MouseEvent) => void
  children: ReactNode
  to?: string
  external?: boolean
  className?: string
  analyticsEventName?: string
}

// LoginLink can either have "to" or "onClick", but not both
type LoginLinkProps = LoginLinkToProps | LoginLinkOnClickProps

export function LoginLink({
  onClick,
  to,
  children,
  external,
  className,
  analyticsEventName = '',
}: LoginLinkProps) {
  const { trackClickEvent } = useAnalytics()
  function handleMouseDown(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    if (analyticsEventName)
      trackClickEvent({
        event: analyticsEventName,
      })
    onClick?.(e)
  }
  if (!!external && !!to) {
    return (
      <ExternalLink to={to} analyticsEventName={analyticsEventName}>
        {children}
      </ExternalLink>
    )
  }

  return (
    <Link className={className} onMouseDown={handleMouseDown} to={to ?? '#'}>
      {children}
    </Link>
  )
}
