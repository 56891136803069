type AlertType = 'error' | 'warn' | 'success'

interface AlertProps {
  type?: AlertType
  className?: string
  message: string | React.ReactNode
}

const getIconClassName = (type: AlertType): string => {
  if (type === 'success') {
    return 'fa-check-circle'
  }
  return 'fa-exclamation-circle'
}

const getAlertColor = (type: AlertType): string => {
  if (type === 'success') {
    return 'green'
  }
  if (type === 'warn') {
    return 'yellow'
  }
  return 'red'
}

export function Alert({ type = 'error', message, className = '' }: AlertProps) {
  const iconClassName = getIconClassName(type)
  const color = getAlertColor(type)
  const textStyle = { color: type === 'warn' ? '#8F5907' : 'inherit' }
  return (
    <div className={className}>
      <div className={`bg-washed-${color}`}>
        <span className={`bg-${color}`} />
        <span style={textStyle}>
          <i className={`${iconClassName}`} />
        </span>
        <div className={type === 'warn' ? 'text-[#8F5907]' : ''}>{message}</div>
      </div>
    </div>
  )
}
