import { MLTextDesktopIcon, TextAndLink, MobileHeader } from 'components'
import { ML_HELP_LINK } from 'utilities'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent } from 'models'

interface Props {
  bgColor?: string
  analyticsPage: string
}

export function Header({ bgColor = 'bg-ink-wash', analyticsPage }: Props) {
  const { isDesktop } = useBreakpoint()
  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 ${bgColor}`}>
      <MLTextDesktopIcon className="hidden h-full fill-green pt-larger pr-36 pl-24 lg:block" />
      <MobileHeader />
      <TextAndLink
        text="Having trouble? "
        external
        link={ML_HELP_LINK}
        className={`${!isDesktop ? 'hidden' : ''} mr-8 mt-12 justify-self-end`}
        linkText="Get help"
        analyticsEventName={`${analyticsPage} Header: ${AnalyticsEvent.Help}`}
      />
    </div>
  )
}
