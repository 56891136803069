import { MLTextMobileIcon, MLTextDesktopIcon, Team, Swoosh } from 'components'
import useBreakpoint from 'hooks/useBreakpoint'

interface Props {
  children: React.ReactNode
  title?: string
  subtitle?: string
  body?: string
  isPasswordPage?: boolean
}

export function LoginLayout({
  children,
  title,
  subtitle,
  body,
  isPasswordPage,
}: Props) {
  const { isDesktop } = useBreakpoint()
  return (
    <section className="grid h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none">
      <div className="col-span-4 grid grid-cols-1 grid-rows-2 overscroll-none bg-green bg-no-repeat lg:max-w-[525px] xl:bg-desktopSwoosh xl:bg-[left_bottom_-50px]">
        <div className="lg:hidden"></div>
        <MLTextDesktopIcon className="hidden h-full w-full fill-white pb-28 pr-36 pl-24 lg:block" />
        <MLTextMobileIcon className="mx-auto mb-4 fill-white lg:hidden" />
        <div className="hidden w-full grid-cols-1 grid-rows-1 lg:grid">
          <Swoosh className="desktop-images -translate-y-64 xl:hidden" />
          <Team className="desktop-images relative h-full px-14" />
        </div>
      </div>
      <div className="col-span-8 justify-items-center overscroll-none sm:mx-auto lg:m-auto">
        <Team
          className={`${
            isPasswordPage
              ? `hidden`
              : `mx-auto h-fit w-full max-w-[450px] px-16 pt-12 pb-16 xs:py-6 lg:hidden`
          }`}
        />
        {(title ?? subtitle) && (
          <div className="flex flex-col px-6 lg:bg-inherit lg:py-4 lg:px-0">
            {title && (
              <h3
                className={`landing-page-title ${
                  isPasswordPage && !isDesktop ? `pt-8 text-ink` : ``
                }`}
              >
                {title}
              </h3>
            )}
            {subtitle && (
              <h3 className="landing-page-subtitle pb-20 pt-2 xs:pb-8 lg:pb-0">
                {subtitle}
              </h3>
            )}
          </div>
        )}
        {body && (
          <h3
            className={`${
              isDesktop
                ? `px-6 pb-7 text-lead text-grey lg:px-0 lg:text-base`
                : `hidden`
            }`}
          >
            {body}
          </h3>
        )}
        <div className="mx-auto px-6 sm:w-[450px] lg:px-0">{children}</div>
      </div>
    </section>
  )
}
